import { useEffect } from "react"
import "./SwitchBtn.css"

const SwitchBtn = ({ isClicked, handleClick, switchContainerClass, circleClass, mainSwitchClass, switchChoices }) => {

    const circleStyles = {
        transform: isClicked == true ? "translate(70px,3px)" : "translate(0px ,3px)"
    }
    return (
        <div className={mainSwitchClass}>
            <p className={isClicked == false ? "active m-0" : "m-0"}>
                {switchChoices.first}
            </p>
            <div className={switchContainerClass}>
                <div
                    style={circleStyles}
                    className={circleClass} onClick={handleClick}></div>
            </div>
            <p className={isClicked == true ? "active m-0" : "m-0"}>
                {switchChoices.second}
            </p>
        </div>
    )
}

export default SwitchBtn