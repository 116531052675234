import { useNavigate } from "react-router-dom"


const usePath = (extraFunction) => {
    const navigate = useNavigate()
    const handleNavigate = (path) => {
        if (extraFunction) {
            extraFunction()
        }
        navigate(path)
    }
    return handleNavigate
}

export default usePath