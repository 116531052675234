import "./FaildPayment.css"
import Lottie from "lottie-react"
import Success from "../../Assets/Images/Payment/Ured1XLmnI.json"
import { useEffect } from "react"
import { usePath } from "../../Custom Hooks"
const FaildPayment = () => {
    const handleNavigate = usePath()
    return (
        <>
            <div className="container-fluid success-payment">
                <div className="row">
                    <div className="col-md-6 f">
                        <Lottie
                            animationData={Success}
                            loop={false} speed={0.5}
                            className="lottie-faild"
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="text">
                            <h2>عملية الدفع</h2>
                            <h4>حدث خطأ ما اثناء عملية الدفع يرجى اعادة المحاولة</h4>
                            <div className="flex-btns">
                                <a className="confirm" onClick={() => handleNavigate("/Supsucriptions")}>اذهب لصفحة الاشتراكات</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaildPayment