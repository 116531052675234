import "./FancyTitle.css"

const FancyTitle = ({ children, className }) => {
    return (
        <h2 className={`fancy-title ${className}`}>
            {children}
        </h2>
    )
}

export default FancyTitle