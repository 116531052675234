import { useContext, useState } from "react"
import { TokenContext } from "../../Context/TokenContext"
import { Navigate, Outlet } from "react-router-dom"
import Cookies from "js-cookies"

const PrivateDa3emRoute = () => {
    const token = useContext(TokenContext)
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.getItem("permissions")))
    return (
        <>
            {
                token != null && permissions?.use_da3em == true ? <Outlet /> : <Navigate to="/AdminPanel" />
            }
        </>
    )
}

export default PrivateDa3emRoute