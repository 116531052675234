import { useContext, useState } from "react"
import { TokenContext } from "../../Context/TokenContext"
import { Navigate, Outlet } from "react-router-dom"
import Cookies from "js-cookies"
const PrivateUserRoutes = () => {
    const token = useContext(TokenContext)
    const [userType, setUserType] = useState(Cookies.getItem("userType"))
    return (
        <>
            {token != null && (userType == "individual" || userType == "user") ? <Outlet /> : <Navigate to="/sign-in" />}
        </>
    )
}

export default PrivateUserRoutes