import { AuthAxios } from "./axios_handler"

class ProfileService {
    static endPoint = "authentications/profile/"
    static put(body) {
        return AuthAxios.put(`${ProfileService.endPoint}`, body)
    }
    static get() {
        return AuthAxios.get(`${ProfileService.endPoint}`)
    }
}
export default ProfileService