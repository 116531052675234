import { useState } from "react"
import "./AddDepartment.css"
import Multiselect from "multiselect-react-dropdown"
import { BlueGlassCon, DashboardBtn } from "../../../Components"
import { getLoadingText } from "../../../Helpers"
import { useTestGet, useTestPost } from "../../../Custom Hooks"
import CompanyMembersService from "../../../Services/CompanyMembersService"
import AllDepartmentsService from "../../../Services/AllDepartmentsService"
const AddDepartment = () => {
    const [data,] = useTestGet(CompanyMembersService.getAllEmp())
    const [name, setName] = useState()
    const [emp_ids, setEmp_ids] = useState();
    const [loading1, postFunc] = useTestPost(AllDepartmentsService.addDepartment)
    const handleClick = () => {
        postFunc({
            emp_ids: emp_ids && emp_ids.map(item => (
                item.user.id
            )),
            name
        })
    }
    const text = getLoadingText(loading1, {
        null: "حفظ",
        success: "تم اضافة القسم بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })

    return (
        <>
            <BlueGlassCon additionalClassName="add-deparment-con">
                <h4>انشاء قسم جديد</h4>
                <div className="add-new-departent-form">
                    <label for="name" className=" col-form-label">الاسم</label>
                    <input type="text" className="form-control" id="name" onChange={(e) => setName(e.target.value)} />
                </div>
                <div className="multi-select-con">
                    <label for="name" className=" col-form-label">اضافة موظفين</label>
                    <Multiselect
                        placeholder=""
                        options={data?.employees}
                        displayValue="full_name"
                        onSelect={(e) => setEmp_ids(e)}
                    />
                </div>
                <div className="save-btn-con">
                    <DashboardBtn onClick={handleClick}>{text}</DashboardBtn>
                </div>
            </BlueGlassCon>
        </>
    )
}

export default AddDepartment