import "./EditMember.css"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { BlueGlassCon, BorderdP, DashboardBtn, Loader } from "../../../Components"
import { useForm } from "react-hook-form"
import { inputs } from "../../../Assets/Data"
import { DynamicInputs, getLoadingText } from "../../../Helpers"
import { zodResolver } from "@hookform/resolvers/zod"
import schema from "../../../Utils/ValidtionsSchemas/EditMembers/schema"
import { useTestGet, useTestPost } from "../../../Custom Hooks"
import CompanyEmployeeService from "../../../Services/CompanyEmployeeService"
const EditMember = () => {
    const { register, handleSubmit, formState: {
        errors
    } } = useForm({
        mode: "onBlur",
        resolver: zodResolver(schema)
    })
    const params = useParams()
    const [data,] = useTestGet(CompanyEmployeeService.getEmp(params.id))
    const [type, setType] = useState()
    const [loading, postFunc] = useTestPost(CompanyEmployeeService.EditEmp, () => {
        window.location.assign("/AdminPanel/members")
    })
    useEffect(() => {
        setType(data && data.user_type)
    }, [data])
    const onSubmit = (data) => {
        postFunc({
            ...data,
            user_type: type
        }, params.id)
    }
    const text = getLoadingText(loading, {
        null: "حفظ",
        success: "تم تعديل المعلومات بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    const EditMemberInputs = inputs.map(row => (
        <div className="row" key={row.id}>
            {row.row.map(input => (
                <div className="col-md-6" key={input.id}>
                    <label htmlFor={input.name} className="form-label">
                        {input.label}
                    </label>
                    <DynamicInputs
                        errors={errors}
                        register={register(input.name)}
                        input={input}
                        placeholder={data && data[input.name]}
                        selectOptions={<>
                            <option value="true">فعال</option>
                            <option value="false">غير فعال</option>
                        </>}
                    />
                </div>
            ))}
        </div>
    ))
    return (
        <>
            {data ?
                <>
                    <BlueGlassCon additionalClassName="add-member-con edit-member-con">
                        <BorderdP additionalClassName="mb-45"> تعديل العضو</BorderdP>
                        <form>
                            {EditMemberInputs}
                            <div className="row">
                                <div className="col-md-4">
                                    <label class="form-check-label" for="inlineRadio2">
                                        <div className="radio-button" onClick={() => setType("user")}
                                            style={{
                                                background: type == "user" ? "rgba(15, 89, 213, 1)" : "transparent"
                                            }}
                                        ></div>
                                        مستخدم
                                        <input type="radio" name="type" id="inlineRadio2" />
                                    </label>
                                </div>
                                <div className="col-md-4">
                                    <label class="form-check-label" for="inlineRadio">
                                        <div className="radio-button"
                                            style={{
                                                background: type == "user" ? "transparent" : "rgba(15, 89, 213, 1)"
                                            }}
                                            onClick={() => setType("admin")}></div>
                                        ادمن
                                        <input type="radio" name="type" id="inlineRadio" />
                                    </label>
                                </div>
                            </div>
                            <DashboardBtn onClick={handleSubmit(onSubmit)}>
                                {text}
                            </DashboardBtn>
                        </form>
                    </BlueGlassCon>
                </> : <Loader />}

        </>
    )
}

export default EditMember