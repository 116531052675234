const UserPermissionsData = [
    {
        id: 1,
        label: "استخدام داعم",
        name: "use_da3em"
    },
    {
        id: 2,
        label: "التيكت",
        name: "use_ticket"
    },
]
export default UserPermissionsData