const DepartmentsCols = [
    {
        title: 'الرقم',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'القسم',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'تعديل',
        dataIndex: 'id',
        key: "id",
    }
]
export default DepartmentsCols