import { useCallback, useState } from "react"
import "./Form.css"
import { usePath, useTestPost } from "../../../Custom Hooks"
import ResetPassowrdService from "../../../Services/ResetPassowrdService."
import { getLoadingText } from "../../../Helpers"
import { BtnWithoutCon } from "../../../Components"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import ResetPassword from "../../../Utils/ValidtionsSchemas/SignIn/ResetPassword"
import { Tooltip } from "antd"

const Form = () => {
    const { register, handleSubmit, formState: {
        errors
    } } = useForm({
        mode: "onBlur",
        resolver: zodResolver(ResetPassword)
    })
    const handleNavigate = usePath()
    const [loading, postFunc] = useTestPost(ResetPassowrdService.post, (res => {
        handleNavigate("/sign-in")
    }))
    const [code, setCode] = useState({
        token: ""
    })
    const handleCode = (e) => {
        const { name, value } = e.target
        if (value !== undefined) {
            const concatCode = code.token + value
            setCode({
                ...code,
                token: concatCode
            })
        }
    }
    const text = getLoadingText(loading, {
        null: "تأكيد",
        success: "تم تغيير كلمة المرور بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    const codeDigits = [...Array(6)].map((_, i) => (
        <input
            key={i}
            type="text"
            className="code"
            onChange={(e) => handleCode(e)}
            minLength="1"
            maxLength="1" />
    ))
    const onSubmit = (data) => {
        postFunc({ ...data, token: code.token })
    }
    return (
        <form className="form-sign-in form-sign-forgot zIndex" onSubmit={handleSubmit(onSubmit)}>
            <div className="confirmation-code-con">
                {codeDigits}
            </div>
            <div className="password-con-valid">
                <Tooltip placement="top" title={errors.password?.message}>
                    <input {...register("password")} type="text" placeholder="كلمة المرور" />
                </Tooltip>
                <Tooltip placement="top" title={errors.confirm_password?.message}>
                    <input {...register("confirm_password")} type="text" placeholder="تأكيد كلمة المرور" />
                </Tooltip>
            </div>
            <BtnWithoutCon className="sign-in-btn"
                text={text} />
        </form>
    )
}

export default Form