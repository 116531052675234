import axios from "axios"
import Cookies from "js-cookies"
const config = {
    baseURL: "https://daeim.net.daeims.com/AIapp",
    // timeout: 20000,
    headers: {
        "Content-Type": "application/json",
    },
};
export const PublicAxios = axios.create(config)
export const AuthAxios = axios.create(config)


AuthAxios.interceptors.request.use(async req => {
    const token = Cookies.getItem("token")
    if (token) req.headers.Authorization = `Bearer ${token}`;
    return req;
})