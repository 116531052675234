
import { createSlice } from "@reduxjs/toolkit";



const userDetailsSlice = createSlice({
    name: "userDetailsSlice",
    initialState: {
        user_type: "",
        status: ""
    },
    reducers: {
        getUserType: (state, action) => {
            state.user_type = action.payload.user_type
        },
        setStatusTicket: (state, action) => {
            state.status = action.payload.status
        }
    },

})
const { actions, reducer } = userDetailsSlice;
export const { getUserType, setStatusTicket } = actions;
export default reducer