import { useState } from "react";
import "./SingleDepartment.css"
import { useNavigate, useParams } from "react-router-dom";
import { singleDepartmentColumns } from "../../../Assets/Data";
import { BlueGlassCon, BorderdP, EditRemoveTableCon, JoinDate, TableCon } from "../../../Components";
import { useTestGet, useTestPost } from "../../../Custom Hooks";
import AllDepartmentsService from "../../../Services/AllDepartmentsService";
import { getLoadingText } from "../../../Helpers";
import CompanyEmployeeService from "../../../Services/CompanyEmployeeService";

const SingleDepartment = () => {
    const params = useParams()
    const [name, setName] = useState()
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const [data, loading] = useTestGet(AllDepartmentsService.singleDepartments(params.id, page), { page })
    const [saveLoading, postFunc] = useTestPost(AllDepartmentsService.editSingleDepartments)
    const [, deleteFunc] = useTestPost(CompanyEmployeeService.deleteEmp, () => {
        window.location.reload()
    })
    const handleNavigate = (id) => {
        navigate(`/AdminPanel/EditMember/${id}`)
    }
    const handleDelete = (id) => {
        deleteFunc(id)
    }
    const columns = [
        ...singleDepartmentColumns,
        {
            title: 'تاريخ الانضمام',
            dataIndex: 'created_at',
            key: "created_at",
            align: "center",
            render: (record) => (
                <JoinDate>{record}</JoinDate>
            )
        },
        {
            title: 'تعديل',
            dataIndex: 'id',
            key: "id",
            render: (record) => (
                <EditRemoveTableCon
                    handleNavigate={() => handleNavigate(record)}
                    handleDelete={() => handleDelete(record)}
                />
            )
        }
    ];
    const handleSave = () => {
        postFunc({ name }, params.id)
    }
    const text = getLoadingText(saveLoading, {
        null: "حفظ",
        success: "تم تعديل القسم بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    return (
        <>
            <h2 className="single-depatment-h2">
                {data?.name}
            </h2>
            <BlueGlassCon additionalClassName="edit-single-department">
                <BorderdP additionalClassName="mb-45">تعديل</BorderdP>
                <div className="edit-department-form">
                    <label htmlFor="edit-depatment">الاسم</label>
                    <input type="text" placeholder={data?.name} onChange={(e) => setName(e.target.value)} />
                </div>
                <h4 >الموظفين</h4>
                <TableCon
                    columns={columns}
                    dataSource={data?.employees?.data}
                    loading={loading}
                    onChange={(page) => setPage(page)}
                    conClassName="single-department-table"
                    additionalElement={<a className="save" onClick={handleSave}>{text}</a>}
                    pageSize={2}
                    totalPages={data?.employees?.total_pages}
                />
            </BlueGlassCon>
        </>
    )
}

export default SingleDepartment