import { useLocation } from "react-router-dom"
import "./DashboardLayout.css"
import { useEffect, useState } from "react"

const DashboardLayout = ({ children, additionalClassName }) => {
    const location = useLocation()
    const [isDepatrmentsPage, setIsDepartmentsPage] = useState(false)
    const [isMembersReport, setIsMembersReport] = useState(false)
    const [isQuestions, setIsQuestions] = useState(false)
    const [isEditProfile, setIsEditProfile] = useState(false)
    const [isPermission, setIsPermission] = useState(false)
    const [isAddMember, setIsAddMember] = useState(false)
    useEffect(() => {
        if (location.pathname == "/AdminPanel") {
            setIsDepartmentsPage(true)
            setIsMembersReport(false)
            setIsQuestions(false)
            setIsEditProfile(false)
            setIsPermission(false)
            setIsAddMember(false)
        }
        else if (location.pathname == "/AdminPanel/MembersReports") {
            setIsMembersReport(true)
            setIsDepartmentsPage(false)
            setIsQuestions(false)
            setIsEditProfile(false)
            setIsPermission(false)
            setIsAddMember(false)
        }
        else if (location.pathname == "/AdminPanel/Questions") {
            setIsQuestions(true)
            setIsDepartmentsPage(false)
            setIsMembersReport(false)
            setIsEditProfile(false)
            setIsPermission(false)
            setIsAddMember(false)
        }
        else if (location.pathname == "/AdminPanel/EditProfile") {
            setIsEditProfile(true)
            setIsDepartmentsPage(false)
            setIsMembersReport(false)
            setIsQuestions(false)
            setIsPermission(false)
            setIsAddMember(false)
        }
        else if (location.pathname == "/AdminPanel/SelectPermissions") {
            setIsPermission(true)
            setIsDepartmentsPage(false)
            setIsMembersReport(false)
            setIsQuestions(false)
            setIsEditProfile(false)
            setIsAddMember(false)
        }
        else if (location.pathname == "/AdminPanel/addMember" || location.pathname.includes("EditMember")) {
            setIsPermission(false)
            setIsDepartmentsPage(false)
            setIsMembersReport(false)
            setIsQuestions(false)
            setIsEditProfile(false)
            setIsAddMember(true)
        }

        else {
            setIsDepartmentsPage(false)
            setIsMembersReport(false)
            setIsQuestions(false)
            setIsEditProfile(false)
            setIsPermission(false)
            setIsAddMember(false)
        }
    }, [location.pathname])
    return (
        <div className={`container-fluid control-panel ${isDepatrmentsPage == true && "isDepartment"}
        ${isMembersReport == true && "isMembersReports"}
        ${isQuestions == true && "isQuestions"} 
        ${isEditProfile == true && "isEditProfile"}
          ${isPermission == true && "isPermission"}
          ${isAddMember == true && "isAddMember"}
          ${additionalClassName}`}>
            {children}
        </div>
    )
}

export default DashboardLayout