import { fadeLeft } from "../../../../Utils/Motions/motion"
import { motion } from "framer-motion"
import { Fragment } from "react";
import "./SwiperCard.css"
import { AboutSwiperData } from "../../../../Assets/Data";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
const SwiperCard = () => {
    const slides = AboutSwiperData.map(slide => (
        <Fragment key={slide.id}>
            <SwiperSlide>
                <h4>ما يميزنا</h4>
                <div className="icons">
                    {slide.icons.map(icon => (
                        <div key={icon.id} className={`icon-con ${icon.id == 2 && "active"}`}>
                            <img src={icon.icon} alt={icon.alt} />
                        </div>
                    ))}
                </div>
                <h5>
                    {slide.text}
                </h5>
            </SwiperSlide>
        </Fragment>
    ))
    return (
        <motion.div
            variants={fadeLeft}
            transition={{
                duration: .5,
                delay: 0
            }}
            initial="hidden"
            whileInView="visible"
            className="card-small ">
            <Swiper
                className="home-swiper"
                modules={[Pagination]}
                slidesPerView={1}
                pagination={{ clickable: true }}
            >
                {slides}
            </Swiper>
        </motion.div>
    )
}

export default SwiperCard