
import "./SignUp.css"
import { Outlet } from "react-router-dom"
import { AccountActionTitle, GlassContainer, Particle, ParticleContainer } from "../../Components"

const SignUp = () => {
    return (
        <ParticleContainer className="linear-background">
            <Particle />
            <GlassContainer className="glass-con">
                <AccountActionTitle className="" text="انشاء حساب" />
                <Outlet />
            </GlassContainer>
        </ParticleContainer>
    )
}

export default SignUp