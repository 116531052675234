import { AuthAxios } from "./axios_handler"

class PermissionService {
    static endPoint = "authentications/change_permissions"
    static getAll() {
        return AuthAxios.get(`${PermissionService.endPoint}/`)
    }
    static editPermissions(body) {
        return AuthAxios.put(`${PermissionService.endPoint}/`, body)
    }
}
export default PermissionService