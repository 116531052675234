import { useState } from "react"
import "./CompanyStep.css"
import { useDispatch } from "react-redux"
import { getUserType } from "../../../redux/userDetailsSlice"
import { Select, BtnWithCon, Steps } from "../../../Components"
import { companySteps } from "../../../Assets/Data"
import usePath from "../../../Custom Hooks/usePath"
import { motion } from "framer-motion"
import { SignUpMotion } from "../../../Utils/Motions/motion"


const CompanyStep = () => {
    const [selectedType, setSelectedType] = useState()
    const [user_type, setUserType] = useState()
    const dispatch = useDispatch()
    const handleNavigate = usePath(() => {
        window.sessionStorage.setItem("user_type", user_type)
    })

    const handleClick = (item) => {
        setSelectedType(item.id)
        setUserType(item.userType)
    }
    const selectBoxes = companySteps.map(item => (
        <Select
            key={item.id}
            title={item.title}
            onClick={() => handleClick(item)}
            selectedType={selectedType}
            selectedClass={item.className}
            isSelected={item.id == selectedType} />
    ))
    return (
        <motion.div
            variants={SignUpMotion}
            initial="initial"
            animate="animate"
            exit="initial"
            className="zIndex"
            transition={{
                duration: .7
            }}
        >
            <div className="steps-one">
                <Steps count={2} />
            </div>
            <div className="select-sign-up">
                {selectBoxes}
            </div>
            <div className="flex-aswad-center">
                <BtnWithCon
                    buttonContainerClass="next-con"
                    buttonClassName="next"
                    handleSubmit={() => handleNavigate("/SignUp")}
                    text="السابق" />
                <BtnWithCon
                    buttonContainerClass="next-con"
                    buttonClassName="next"
                    handleSubmit={() => handleNavigate(user_type == "companyuser" ? "/SignUp/ExistCompany" : "/SignUp/NewCompany",)}
                    text="التالي" />
            </div>
        </motion.div>
    )
}

export default CompanyStep
