import Vector from "../../../Assets/Images/Vector.svg"
import { BtnWithCon } from "../../"
import "./Card.css"
const Card = ({ data, className, onClick }) => {
    return (
        <div className={className}>
            <h5>{data.name}</h5>
            <h6>{data.price}</h6>
            <p>يوم {data.duration}</p>
            <ul>
                {data.descriptions.map(description => (
                    <li>

                        {description}
                        <img src={Vector} /></li>
                ))}
            </ul>
            <BtnWithCon text="اشترك الان" buttonContainerClass="btn-up-con" handleSubmit={onClick} />

        </div>
    )
}

export default Card