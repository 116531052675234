const JoinRequestCols = [
    {
        title: 'الاسم الكامل',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'رقم الهاتف',
        dataIndex: 'phone_number',
        key: 'phone_number',
    },
    {
        title: 'الوظيفة',
        dataIndex: 'job_title',
        key: 'job_title',
    },
    {
        title: "",
        dataIndex: 'id',
        key: 'id',
        isJoinBtn: true
    },
]
export default JoinRequestCols