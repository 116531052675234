import { useState } from "react";
import "./SearchHistory.css"
import { useTestGet } from "../../../Custom Hooks";
import { useNavigate } from "react-router-dom";
import SearchHistoryService from "../../../Services/SearchHistoryService";
import { CreateTicketsPanner, TableCon } from "../../../Components";
import { SearchHistoryData } from "../../../Assets/Data";
const SearchHistory = ({ pageName }) => {
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const [dataSource, loading] = useTestGet(SearchHistoryService.get(page), {
        page: page
    })
    const handleNavigate = (id) => {
        if (pageName == "user") {
            navigate(`/ControlPanel/chat/${id}`)
        }
        else {
            navigate(`/AdminPanel/Questions/chat/${id}`)
        }

    }
    const cols = SearchHistoryData.map(item => {
        if (item.isBtn) {
            item.render = (record) => (
                <a className="table-details" onClick={() => handleNavigate(record)}>
                    تفاصيل</a>
            )
            return item
        }
        else {
            return item
        }
    })
    return (
        <>
            <div className="tickets-con">
                <CreateTicketsPanner
                    text="هل تواجه مشكلة؟ اسأل داعم"
                    btnText="داعم"
                    onClick={() => navigate("/AdminPanel/Questions")}
                />
                <TableCon
                    columns={cols}
                    dataSource={dataSource?.chats}
                    loading={loading}
                    onChange={(page) => setPage(page)}
                    conClassName="table-con"
                    pageSize={6}
                    totalPages={dataSource?.total_pages}
                />
            </div>
        </>
    )
}

export default SearchHistory