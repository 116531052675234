import { memo, useEffect, useState } from "react"
import "./SingleAiChat.css"
import { useTestPost } from "../../../Custom Hooks"
import { useParams } from "react-router-dom"
import { IoAttach } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import Base64 from "../../../Helpers/Base64"
import ChatBot from "../../../Services/ChatBot"
import { likeDisLike } from "../../../Assets/Data"
import Typist from 'react-typist';
import Skeleton from "../../../Components/Skeleton/Skeleton"
import { DashboardNav } from "../../../Sections"
import { ChatDetailsBox } from "../../../Components";
const SingleAiChat = () => {
    const [message, setMessage] = useState()
    const [image, setImage] = useState()
    const [typeClicked, setTypeClicked] = useState()
    const params = useParams()
    const [replayLoading, setrReplayLoading] = useState(false)
    const [loading, postFunc, d] = useTestPost(ChatBot.post)
    const [, likeFunc] = useTestPost(ChatBot.likeDisLike)
    const handleSubmit = (e) => {
        e.preventDefault()
        setrReplayLoading(!replayLoading)
    }
    useEffect(() => {
        handleClick()
    }, [replayLoading])
    const handleText = (text, isTyping) => {
        let result = [];
        const textSplit = text.split("\n").map((item, index) => {
            if (item.length == 0) {
                result.push(<br key={index} />);
            } else if (item.includes("**")) {
                const textWithoutBoldMarkers = item.replace(/\*\*/g, '');
                result.push(<span key={index} style={{ fontWeight: 'bold' }}>{textWithoutBoldMarkers}</span>);
            } else {
                const textWithoutBoldMarkers = item.replace(/\*/g, '-');
                result.push(textWithoutBoldMarkers);
            }
        });
        if (isTyping) {
            return (
                <Typist avgTypingDelay={30} cursor={{ show: false }}>
                    {result}
                </Typist>
            );
        } else {
            return result;
        }
    };
    const handleImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await Base64(file)
        setImage(base64)
    }
    const handleTypeClicked = (item) => {
        setTypeClicked(item.id)
        likeFunc({ action: item.action }, params.p)
    }
    const likeDisLikeIcons = likeDisLike.map(item => (
        <div className={`svg ${typeClicked != null ?
            item.id == typeClicked ? "active" : "un-active" : ""}`} onClick={() => handleTypeClicked(item)}>
            {item.icon}
        </div>
    ))
    const handleClick = () => {
        if (image != null) {
            postFunc({ message, image }, params.p)
        }
        else {
            postFunc({ message }, params.p)
        }
        setMessage("");
    }
    return (
        <>
            <DashboardNav />
            <ChatDetailsBox />
            <div className="chat-loader-con">
                {/* {loadingChat == true ? <ChatLoader /> : <></>} */}
                <div className="tikect-chat-con row">
                    {d == null ? <Skeleton length={3} /> : <>
                        <div className="col-md-1 p-0">
                            <h6>01</h6>
                        </div>
                        <div className="col-md-7 p-0">
                            {
                                d && d.data && d.data.chat_history && d.data.chat_history.map((item, index) => (
                                    <>
                                        {item.sender == "User" ? <div className="me-con">
                                            <p className="me">انت</p>
                                            <h6>{item && item.text}</h6>
                                            <div className="hr"></div>
                                        </div> :
                                            <>
                                                <div className="him-con">
                                                    <p className="him">داعم</p>
                                                    <h6>
                                                        {index === d.data.chat_history.length - 1 ? (
                                                            <>
                                                                {item && handleText(item.text, true)}
                                                                <div className="does-replay">
                                                                    <p>هل كان هذا الرد مفيداََ؟</p>
                                                                    {likeDisLikeIcons}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            item && handleText(item.text, false)
                                                        )}
                                                    </h6>
                                                </div>

                                                {index === d.data.chat_history.length - 1 && (loading == "loading") &&
                                                    <div className="flex-loading">
                                                        <div class="typing-loader"></div>
                                                    </div>
                                                }
                                            </>}

                                    </>
                                ))
                            }
                        </div>
                    </>}


                    <form onSubmit={(e) => handleSubmit(e)} className="replay-input-con ai-input-con" >
                        <div className="input-img-send-con">
                            <div className="btn-send-bot" onClick={handleClick}>
                                <IoIosArrowBack size={25} />
                            </div>
                            <label>
                                <IoAttach className="icon" size={25} />
                                <input type="file" onChange={(e) => handleImage(e)} />
                            </label>
                            <input type="text" placeholder="رد" onChange={(e) => setMessage(e.target.value)} value={message} />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default memo(SingleAiChat)

