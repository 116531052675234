import { AuthAxios } from "./axios_handler"

class DepartmentsService {
    static endPoint = "authentications/distinct-sections/"
    static get() {
        try {
            return AuthAxios.get(`${DepartmentsService.endPoint}`)
        }
        catch (err) {
            console.log("err")
        }
    }
}
export default DepartmentsService