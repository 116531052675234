import { DashboardLayout } from "../../Components"
import Robot from "../../Assets/Images/Dashboard/sfee1.png"
import "./ConfirmEmail.css"

const ConfirmEmail = () => {
    return (
        <DashboardLayout additionalClassName="flex-col-aswad-center">
            <h2>داعم</h2>
            <img src={Robot} alt="icon" />
            <p>الرجاء التأكد من الايميل لتفعيل الحساب</p>
        </DashboardLayout>
    )
}

export default ConfirmEmail