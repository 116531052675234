import { NavLink } from "react-router-dom"
import "./CheckLinksRoles.css"
import { memo, useState } from "react"
import Cookies from "js-cookies"
const CheckLinksRoles = ({ link }) => {
    const [userType, setUserType] = useState(Cookies.getItem("userType"))
    const [isUser, setIsUser] = useState(userType == "individual" || userType == "user" ? true : false)
    const [isAdmin, setIsAdmin] = useState(userType == "admin" || userType == "superadmin" ? true : false)
    if (link.isDashboard == true && (isAdmin == true || isUser == true)) {
        return <NavLink to={isAdmin == true ? "/AdminPanel" :
            isUser == true && "/ControlPanel"} >
            <li>
                لوحة داعم
            </li>
        </NavLink>
    }
    else if (link.isDashboard == false) {
        return <NavLink to={link.to}>
            <li>
                {link.text}
            </li>
        </NavLink>
    }
    else {
        return ""
    }
}

export default memo(CheckLinksRoles) 