import "./AdminControlPanel.css"
import { DepartmentsCard, Loader, WidgetChecker } from "../../../Components"
import { useTestGet } from "../../../Custom Hooks"
import AllDepartmentsService from "../../../Services/AllDepartmentsService"
import { useNavigate } from "react-router-dom"
import { WidgetData } from "../../../Assets/Data"
import ChatBot from "../../../Services/ChatBot"
const AdminControlPanel = () => {
    const [data,] = useTestGet(AllDepartmentsService.getAll())
    const [widgetData,] = useTestGet(ChatBot.chatBotStatus())
    const navigate = useNavigate()
    const handleNavigate = (id) => {
        navigate(`SingleDepartment/${id}`)
    }
    const handleNavAddDep = () => {
        navigate("AddDepartment")
    }
    const allDepartments = data && data?.sections.map(item => (
        <>
            <DepartmentsCard
                onClick={() => handleNavigate(item.id)}
                item={item}
            />
        </>))

    const widgets = WidgetData.map(widget => (
        <>
            <WidgetChecker
                widget={widget}
                widgetData={widgetData}
            />
        </>
    ))
    return (
        <>
            {data && widgetData ?
                <>
                    <div className="widgtes-con">
                        {widgets}
                    </div>
                    <div className="department-con">
                        <p className="m-0">الأقسام التي تديرها</p>
                        <h6 className="m-0" onClick={handleNavAddDep}>+ انشاء قسم جديد</h6>
                    </div>
                    <div className="dipartment-widgets-con">
                        {allDepartments}
                    </div>
                </>
                : <Loader />}

        </>
    )
}

export default AdminControlPanel