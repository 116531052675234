import { GradientHeaderButton, IsHaveToken } from "../../../Components"
import { usePath } from "../../../Custom Hooks"
import "./Hero.css"


const Hero = () => {
    const handleNavigate = usePath()
    const authElements = <IsHaveToken
        AuthEle={<></>}
        UnAuthEle={<GradientHeaderButton
            text="ابدأ الان" additionalClassName="hero-btn"
            onClick={() => handleNavigate("/sign-in")} />}
    />
    return (
        <>
            <div className="hero container-fluid">
                <h1>جملة تسويقية عن خدمة داعم وما يمكن </h1>
                <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                    Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.
                </p>
                {authElements}
            </div>
        </>
    )
}

export default Hero