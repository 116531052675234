import { AuthAxios } from "./axios_handler"

class ChatBot {
    static endPoint = "chatbot"
    static post(body, id) {
        return AuthAxios.post(`${ChatBot.endPoint}/send-message${id ? `/${id}/` : "/"}`, body)
    }
    static get(id) {
        return AuthAxios.get(`${ChatBot.endPoint}/get-history/${id}/`)
    }
    static likeDisLike = (body, id) => {
        return AuthAxios.post(`${ChatBot.endPoint}/like-dislike/${id}/`, body)
    }
    static chatBotStatus = () => {
        return AuthAxios.get(`${ChatBot.endPoint}/control_panel_stats/`)
    }
}
export default ChatBot