import { AuthAxios } from "./axios_handler"

class CompanyEmployeeService {
    static endPoint = "authentications/companyemp"
    static deleteEmp(id) {
        return AuthAxios.delete(`${CompanyEmployeeService.endPoint}/${id}/`)
    }
    static getEmp(id) {
        return AuthAxios.get(`${CompanyEmployeeService.endPoint}/${id}/`)
    }
    static EditEmp(body, id) {
        return AuthAxios.put(`${CompanyEmployeeService.endPoint}/${id}/`, body)
    }
    static getAll(page) {
        return AuthAxios.get(`${CompanyEmployeeService.endPoint}/?page=${page}`)
    }
    static addMember(body) {
        return AuthAxios.post(`${CompanyEmployeeService.endPoint}/`, body)
    }
}
export default CompanyEmployeeService