import "./JoinRequest.css"
import { useTestGet, useTestPost } from "../../../Custom Hooks"
import JoinRequestService from "../../../Services/JoinRequestService";
import CompanyEmployeeService from "../../../Services/CompanyEmployeeService";
import JoinRequestCols from "../../../Assets/Data/Dashboard/JoinRequest/JoinRequestCols";
import { BlueGlassCon, BorderdP, TableCon } from "../../../Components"
import { useState } from "react";
const JoinRequest = () => {
    const [page, setPage] = useState(1)
    const [dataSource, loading] = useTestGet(JoinRequestService.get())
    const [loading1, postFunc] = useTestPost(CompanyEmployeeService.EditEmp, () => {
        window.location.reload()
    })
    const handleApprove = (id) => {
        postFunc({
            accepted: "approved"
        }, id)
    }
    const handleDenied = (id) => {
        postFunc({
            accepted: "denied"
        }, id)
    }
    const cols = JoinRequestCols.map(item => {
        if (item.isJoinBtn) {
            item.render = (record) => (
                <div className="join-request-btn">
                    <a className="approve" onClick={() => handleApprove(record)}>قبول</a>
                    <a className="denied" onClick={() => handleDenied(record)}>رفض</a>
                </div>)

            return item
        }
        else {
            return item
        }
    })

    return (
        <>
            <BlueGlassCon additionalClassName="members-con">
                <div className="text-add-members-con">
                    <BorderdP>
                        طلبات الانضمام
                    </BorderdP>
                </div>
                <TableCon
                    columns={cols}
                    dataSource={dataSource}
                    loading={loading}
                    onChange={(page) => setPage(page)}
                    conClassName="table-add-member-con"
                    pageSize={5}
                    totalPages={dataSource?.pages}
                />
            </BlueGlassCon>
        </>
    )
}

export default JoinRequest