import { useParams } from "react-router-dom"
import "./SingleTicket.css"
import { IoAttach } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import Cookies from "js-cookies"
import { Fragment, useState } from "react"
import Base64 from "../../../Helpers/Base64"
import { ChatBox, ChatDetailsBox, Comment } from "../../../Components"
import { useTestGet, useTestPost } from "../../../Custom Hooks";
import TicketsService from "../../../Services/TicketsService";
const SingleTicket = () => {
    const [replayLoading, setrReplayLoading] = useState(1)
    const params = useParams()
    const [userId, setUserId] = useState(Cookies.getItem("userId"))
    const [replay, setReplay] = useState()
    const [file, setFile] = useState()
    const [, postFunc] = useTestPost(TicketsService.comment, () => {
        setFile(null)
        setReplay("")
        setrReplayLoading(prev => prev + 1)
    })
    const [data, loading] = useTestGet(TicketsService.getTicketsDetails(params.id), { replayLoading })
    const handleImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await Base64(file)
        setFile(base64)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        postFunc({ file, comment: replay }, params.id)
    }
    const handleClick = () => {
        if (file != null) {
            postFunc({ file, comment: replay }, params.id)
        }
        else {
            postFunc({ comment: replay }, params.id)
        }
    }
    const comments = data?.comments?.map(item => (
        <Fragment key={item.id}>
            {item.user == userId ?
                <Comment text="انت"
                    conClassName="me-con"
                    description={item?.comment}
                    pClassName="me"
                /> :
                <Comment
                    text="الدعم"
                    conClassName="him-con"
                    description={item?.comment}
                    pClassName="him" />
            }
        </Fragment>
    ))
    return (
        <>
            <ChatDetailsBox />
            <ChatBox>
                <div className="col-md-1 p-0">
                    <h6>01</h6>
                </div>
                <div className="col-md-7 p-0">
                    <Comment
                        text="انت"
                        description={data?.description}
                        conClassName="me-con"
                        pClassName="me"
                    />
                    {comments}
                </div>
                <form onSubmit={(e) => handleSubmit(e)} className="replay-input-con ai-input-con" >
                    <div className="input-img-send-con">
                        <div className="btn-send-bot" onClick={handleClick}>
                            <IoIosArrowBack size={25} />
                        </div>
                        <label>
                            <IoAttach className="icon" size={25} />
                            <input type="file" onChange={(e) => handleImage(e)} />
                        </label>
                        <input type="text" placeholder="رد" onChange={(e) => setReplay(e.target.value)} value={replay} />
                    </div>
                </form>
            </ChatBox>
        </>
    )
}

export default SingleTicket