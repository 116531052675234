import "./ForgotPassword.css"
import { AccountActionTitle, GlassContainer, Particle, ParticleContainer } from "../../Components"
import Logo from "../../Assets/Images/logo.svg"
import { ForgotPasswordForm } from "../../Sections"
const ForgotPassword = () => {
    return (
        <ParticleContainer className="linear-background sign-in forget-password">
            <Particle />
            <GlassContainer className="glass-con row">
                <AccountActionTitle className="h2-sign-in" text="اعادة تعيين كلمة المرور" />
                <div className="col-md-6 zIndex">
                    <div className="forget-logo-con">
                        <img src={Logo} className="img-fluid" />
                    </div>
                </div>
                <div className="col-md-6 zIndex">
                    <ForgotPasswordForm />
                </div>
            </GlassContainer>
        </ParticleContainer>
    )
}

export default ForgotPassword