import { useCallback, useState } from 'react'

const useTestPost = (endPoint, onSuccess) => {
    const [loading, setLoading] = useState()
    const [data, setData] = useState()
    const [error, setError] = useState()
    const handleSubmit = useCallback((body, extraParams) => {
        setLoading("loading")
        endPoint(body, extraParams && extraParams)
            .then(res => {
                setLoading(true)
                setData(res)
                if (onSuccess) {
                    onSuccess(res)
                }
            })
            .catch(err => {
                if (err?.response?.status == 400 || err?.response?.status == 401 || err?.response?.status == 404) {
                    setError(err.response.data.detail)
                }
                else if (err?.message == "Network Error") {
                    setError("الرجاء الاتصال بالانترنت")
                }
                else {
                    setError("حدث خطأ ما")
                }
                setLoading(false)
                setTimeout(() => {
                    setLoading(null)
                }, 2000)
            })
    }, [])
    return [loading, handleSubmit, data, error]
}

export default useTestPost