import "./Header.css"
import Logo from "../../Assets/Images/logo.svg"
import { usePath } from "../../Custom Hooks"
import burger from "../../Assets/Images/burger.svg"
import { Fragment, useState } from "react"
import Cookies from "js-cookies"
import { HeaderLinks } from "../../Assets/Data"
import { CheckLinksRoles, GradientHeaderButton, IsHaveToken } from "../../Components"
const Header = () => {
    const handleNavigate = usePath()
    const [isNav, setIsNav] = useState(false)
    const handleSignOut = () => {
        Cookies.removeItem("token")
        Cookies.removeItem("userType")
        Cookies.removeItem("userId")
        window.location.assign("/")
    }
    const links = HeaderLinks.map(link => (
        <Fragment key={link.id}>
            <CheckLinksRoles link={link} />
        </Fragment>
    ))
    const authElements = <IsHaveToken
        AuthEle={<GradientHeaderButton text="تسجيل خروج" onClick={() => handleSignOut()} />}
        UnAuthEle={<div>
            <GradientHeaderButton text="تسجيل الدخول" onClick={() => handleNavigate("/sign-in")} />
            <GradientHeaderButton text="تسجيل" onClick={() => handleNavigate("/SignUp")} />
        </div>}
    />
    return (
        <>
            <header className='header container'>
                <div className='logo'>
                    <img src={Logo} alt='logo' className="img-fluid" />
                </div>
                <nav className="nav d-none d-lg-block">
                    <ul>
                        {links}
                    </ul>
                </nav>

                {authElements}
                <div className="burger-con d-block d-lg-none" onClick={() => setIsNav(!isNav)}>
                    <img src={burger} alt="menu" />
                </div>
            </header>
            <header className="sm-header" style={{
                height: isNav == false ? "0%" : "100%",
                padding: isNav == false ? "0" : "30px",
            }}>
                <ul style={{
                    display: isNav == false ? "none" : "block"
                }}>
                    {links}
                </ul>
            </header>
        </>
    )
}

export default Header