import './App.css';
import { RouterProvider } from 'react-router-dom';
import router from './Routes/Router';
import { AnimatePresence } from 'framer-motion';


function App() {
  return (
    <>
      <AnimatePresence mode='wait'>
        <RouterProvider router={router} />
      </AnimatePresence>
    </>

  );
}

export default App;
