import "./MembersReports.css"
import { PieChart, Pie, Cell } from 'recharts';
import useTestGet from "../../../Custom Hooks/useTestGet";
import MembersReportsService from "../../../Services/MembersReportsService";
import { useState } from "react";
import { MembersReportsCols, selects } from "../../../Assets/Data";
import useInput from "../../../Custom Hooks/useInput";
import DepartmentsService from "../../../Services/DepartmentsService";
import useTestPost from "../../../Custom Hooks/useTestPost";
import { TableCon } from "../../../Components";
const MembersReports = () => {
    const [isDownload, setIsDownload] = useState(false)
    const [selectData, handleChange] = useInput()
    const [isFilter, setIsFilter] = useState(false)
    const [page, setPage] = useState(1)
    const [data1,] = useTestGet(MembersReportsService.getStatus())
    const [departments,] = useTestGet(DepartmentsService.get())
    const [loadingPdf, DownloadFunc, pdf] = useTestPost(MembersReportsService.downloadPdf)
    const [dataSource, loading] = useTestGet(MembersReportsService.getTableDetails(selectData?.month, selectData?.section_name, selectData?.like, page), {
        page,
        isFilter
    })
    const data = [
        { name: 'Likes', value: data1 && data1.like_percentage },
        { name: 'Dislikes', value: data1 && data1.dislike_percentage }
    ];
    const COLORS = ['#0088FE', 'rgba(255, 70, 70, 1)'];
    const columns = MembersReportsCols.map(item => item)

    const dynamicSelects = selects.map(select => (
        <div class="mb-1">
            <label htmlFor="exampleFormControlInput1" className="form-label">{select.label}</label>
            <select name={select.name} className={select.className} onChange={(e) => handleChange(e)}>
                {select.defaultOption}
                {select.label == "حسب القسم" ? departments?.map(item => (
                    <option value={item}>{item}</option>
                )) : select.options?.map(option => (
                    <option value={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    ))
    const handleFilter = () => {
        setIsFilter(!isFilter)
    }
    const handleDownload = () => {
        DownloadFunc()
        if (pdf) {
            const blob = pdf.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'output.pdf'); // or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    }
    return (
        <>
            <div className="members-report-con">
                <div className="highlights-con">
                    <p>تقارير الأعضاء</p>
                    <div className="circle-text-con">
                        <div className="circle">
                            <PieChart width={170} height={170}>
                                <Pie
                                    data={data}
                                    labelLine={false}
                                    outerRadius={80} // Outer radius of the pie chart
                                    innerRadius={40} // Inner radius to create a hole
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {data.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </div>
                        <div className="text">
                            <h5><span className="inner-circle-sucess"></span> {data1 && Math.round(data1.like_percentage)}% </h5>
                            <h6>نسبة المشاكل التي تم حلها</h6>
                        </div>
                        <div className="text">
                            <h5><span className="inner-circle-faild"></span> {data1 && Math.round(data1.dislike_percentage)}% </h5>
                            <h6>نسبة المشاكل الغير محلولة</h6>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <TableCon
                            columns={columns}
                            dataSource={dataSource?.conversations}
                            loading={loading}
                            onChange={(page) => setPage(page)}
                            conClassName="table-add-member-con"
                            pageSize={5}
                            totalPages={dataSource?.pages}
                        />
                    </div>
                    <div className="col-md-4">
                        <div className="filter-search-con">
                            <h4>فلترة البحث</h4>
                            <div className="filters">
                                {dynamicSelects}
                            </div>
                            <div className="flex-aswad-center">
                                <a className="apply-btn" onClick={handleFilter}>تطبيق</a>
                            </div>
                        </div>
                        <div className="download-reports">
                            <p className="m-0">تحميل بيانات الشهر الحالي</p>
                            <a className="apply-btn download-btn" onClick={handleDownload}>تحميل</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembersReports