import "./Skeleton.css"

const Skeleton = ({ length }) => {
    return (
        <>
            {[...Array(length)].map(_ => (
                <div className="skeleton"></div>
            ))}
        </>
    )
}

export default Skeleton