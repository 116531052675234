import "./ChatDetailsBox.css"

const ChatDetailsBox = () => {
    return (
        <div className="single-tickets-details">
            <div className="number-results">
                <p className="m-0">الرقم</p>
                <p className="m-0">النتيجة</p>
            </div>
        </div>
    )
}

export default ChatDetailsBox