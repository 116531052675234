import { Outlet } from "react-router-dom"
import TokenContextFunc from "./Context/TokenContext"

const Root = () => {
    return (
        <TokenContextFunc>
            <div className="App">
                <Outlet />
            </div>
        </TokenContextFunc>
    )
}

export default Root