import { Abilities, AboutUs, Footer, Header, Hero, JoinUs, YourNextStep } from "../../Sections"
import "./Home.css"

const Home = () => {
    return (
        <>
            <Header />
            <Hero />
            <Abilities />
            <AboutUs />
            <JoinUs />
            <YourNextStep />
            <Footer />
        </>
    )
}

export default Home