import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi"
import "./AddMember.css"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { AddMemberInputs } from "../../../Assets/Data"
import { DynamicInputs, getLoadingText } from "../../../Helpers"
import { BlueGlassCon, BorderdP } from "../../../Components"
import { usePath, useTestPost } from "../../../Custom Hooks"
import CompanyEmployeeService from "../../../Services/CompanyEmployeeService"
const AddMember = () => {
    const { register, handleSubmit, control, setValue, formState: {
        errors
    } } = useForm()
    const handleNavigate = usePath()
    const [type, setType] = useState("admin")
    const [loading, postFunc] = useTestPost(CompanyEmployeeService.addMember, () => {
        handleNavigate("/AdminPanel/members")
    })
    const onSubmit = (data) => {
        postFunc({ ...data, user_type: type })
    }
    const text = getLoadingText(loading, {
        null: "ارسال دعوة",
        success: "تم اضافة العضو بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    const inputs = AddMemberInputs.map(row => (
        <div className="row">
            {row.row.map(col => (
                <div className="col-md-6">
                    <label htmlFor={col.name} className="form-label">
                        {col.label}
                    </label>
                    <DynamicInputs
                        setValue={setValue}
                        control={control}
                        register={register(col.name)}
                        input={col}
                        errors={errors}
                        RandomCodeDetails={{
                            icon: <GiPerspectiveDiceSixFacesRandom className="icon-dice" />
                        }}
                    />
                </div>
            ))}
        </div>
    ))
    return (
        <>
            <BlueGlassCon additionalClassName="add-member-con">
                <BorderdP> اضافة عضو</BorderdP>
                <form>
                    {inputs}
                    <div className="row">
                        <div className="col-md-4">
                            <label class="form-check-label" for="inlineRadio2">
                                <div className="radio-button" onClick={() => setType("user")}
                                    style={{
                                        background: type == "user" ? "rgba(15, 89, 213, 1)" : "transparent"
                                    }}
                                ></div>
                                مستخدم
                                <input type="radio" name="type" id="inlineRadio2" />
                            </label>
                        </div>
                        <div className="col-md-4">
                            <label class="form-check-label" for="inlineRadio">
                                <div className="radio-button"
                                    style={{
                                        background: type == "user" ? "transparent" : "rgba(15, 89, 213, 1)"
                                    }}
                                    onClick={() => setType("admin")}></div>
                                ادمن
                                <input type="radio" name="type" id="inlineRadio" />
                            </label>
                        </div>
                    </div>
                    <div className="send-invite">
                        <a className="invite-btn" onClick={handleSubmit(onSubmit)}>
                            {text}
                        </a>
                    </div>
                </form>
            </BlueGlassCon>
        </>
    )
}

export default AddMember