import { useEffect, useState } from "react"


const useTestGet = (endPoint, dependency, onSuccess) => {
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true)
        endPoint
            .then(res => {
                setLoading(false)
                setData(res.data)
                if (onSuccess) {
                    onSuccess(res)
                }
            })
            .catch(error => {
                setLoading(true)
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log("error");
                }
            })
    }, [dependency?.replayLoading,
    dependency?.page,
    dependency?.isFilter,
    dependency?.status])
    return [data, loading]
}

export default useTestGet