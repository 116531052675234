const AddMemberInputs = [
    {
        id: 1,
        row: [
            {
                id: 1,
                name: "full_name",
                isInput: true,
                className: "form-control",
                label: "الاسم الكامل",
                type: "text"
            },
            {
                id: 2,
                name: "username",
                isInput: true,
                className: "form-control",
                label: "اسم المستخدم",
                type: "text"
            }
        ]
    },
    {
        id: 2,
        row: [
            {
                id: 1,
                name: "email",
                isInput: true,
                className: "form-control",
                label: "البريد الالكتروني",
                type: "email"
            },
            {
                id: 2,
                isRandomCode: true,
                className: "form-control",
                name: "password",
                type: "text"
            }
        ]
    },
    {
        id: 3,
        row: [
            {
                id: 1,
                name: "career",
                isInput: true,
                className: "form-control",
                label: "المهنة",
                type: "text"
            },
            {
                id: 2,
                name: "job_title",
                isInput: true,
                className: "form-control",
                label: "الوظيفة",
                type: "text"
            }
        ]
    }
]
export default AddMemberInputs