const HeaderLinks = [
    {
        id: 1,
        to: "/",
        text: "الصفحة الرئيسية",
        isDashboard: false
    },
    {
        id: 2,
        to: "/Supsucriptions",
        text: "باقات الاشتراك",
        isDashboard: false
    },
    {
        id: 3,
        text: "لوحة داعم",
        isDashboard: true
    }
]

export default HeaderLinks