import { createContext, useState } from "react";
import Cookies from "js-cookies"
export const TokenContext = createContext()
const TokenContextFunc = (props) => {
    const [token, setToken] = useState(Cookies.getItem("token"))
    return (
        <TokenContext.Provider value={token}>
            {props.children}
        </TokenContext.Provider>
    )
}
export default TokenContextFunc