import { NavLink } from "react-router-dom";
import "./FancyNavLinks.css"
import { IoIosArrowBack } from "react-icons/io";
const FancyNavLinks = ({ link, isActive }) => {
    const activeClass = isActive && link.isTicket ? 'li-ticket' : '';
    return (
        <NavLink className={activeClass} key={link.id} end={true} to={link.path}>
            <li className={link.liClassName}>
                <div className="text-li-icon">
                    <img src={link.icon} />
                    {link.text}
                </div>
                <IoIosArrowBack className="icon" />
            </li>
        </NavLink>
    )
}

export default FancyNavLinks