import "./JoinUsGlassCon.css"

const JoinUsGlassCon = ({ children }) => {
    return (
        <>
            <div className="join-us-glass-con">
                {children}
            </div>
        </>
    )
}

export default JoinUsGlassCon