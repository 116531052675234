const companySteps = [
    {
        id: 1,
        title: "انشاء حساب شركة جديدة",
        userType: "companysuperadmin",
        className: "select1"
    },
    {
        id: 2,
        title: " انشاء حساب في شركة موجودة",
        userType: "companyuser",
        className: "select2"
    }
]
export default companySteps