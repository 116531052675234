import { memo } from "react"
import "./ChatLoader.css"

const ChatLoader = () => {
    return (
        <>
            <div className="loader-chat">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>
        </>
    )
}

export default memo(ChatLoader) 