import "./AboutUs.css"
import { AboutCard, ExtraSmallCard, FancyTitle, SwiperCard } from "../../../Components";
import { fadeBottom, fadeUp } from "../../../Utils/Motions/motion";
const AboutUs = () => {
    return (
        <div className="container-fluid about-us">
            <div className="row">
                <div className="col-lg-3 s-three">
                    <SwiperCard />
                </div>
                <div className="col-lg-6 s-two">
                    <AboutCard
                        variants={fadeUp}
                        title="+800"
                        description="انضم الان الى اسرة عملاء داعم واستمتع بالتجربة!" />
                </div>
                <div className="col-lg-3 s-one">
                    <div className="text">
                        <FancyTitle className="blue">داعم افراد</FancyTitle>
                        <p>نص من 4 ل 5 اسطر يوضح رؤية وخدمات الشركة</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 s-one">
                    <div className="text">
                        <FancyTitle className="pink">داعم شركات</FancyTitle>
                        <p>نص من 4 ل 5 اسطر يوضح رؤية وخدمات الشركة</p>
                    </div>
                </div>
                <div className="col-lg-6 s-two">
                    <AboutCard
                        variants={fadeBottom}
                        title="+800"
                        description="انضم الان الى اسرة عملاء داعم واستمتع بالتجربة!"
                        className="card-second-row"
                    />
                </div>
                <div className="col-lg-3 s-three s-flex">
                    <ExtraSmallCard
                        title="+2300"
                        partialTitle="مشكلة تم حلها مع داعم"
                        description="قم بحل مشاكلك ومشاكل موظفيك بالاستعانة بمحرك داعم!"
                    />
                </div>
            </div>
        </div>
    )
}

export default AboutUs