import "./Questions.css"
import Robot from "../../../Assets/Images/Dashboard/sfee1.png"
import { useState } from "react"
import useTestPost from "../../../Custom Hooks/useTestPost"
import ChatBot from "../../../Services/ChatBot"
import { IoIosArrowBack } from "react-icons/io";
import { usePath } from "../../../Custom Hooks"
import { useNavigate } from "react-router-dom"
import Base64 from "../../../Helpers/Base64"
import { IoAttach } from "react-icons/io5";
import { ChatLoader } from "../../../Components"



const Questions = () => {
    const [image, setImage] = useState()
    const [message, setMessage] = useState()
    const navigate = useNavigate()
    const handleNavigate = usePath()
    const [loading, postFunc, data] = useTestPost(ChatBot.post, (res) => {
        handleNavigate(`chat/${res.data.conversation_id}`)
    })
    const handleImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await Base64(file)
        setImage(base64)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (image != null) {
            postFunc({ message, image })
        }
        else {
            postFunc({ message })
        }
        setMessage("");
    }

    return (
        <>
            <form className="control-panel-content">
                <h2>داعم</h2>
                <img src={Robot} />
                <div className="ai-text-area">
                    <form onSubmit={(e) => handleSubmit(e)} className="replay-input-con ai-input-con" >
                        {loading == "loading" || loading == false ? <ChatLoader /> : <></>}
                        <div className="input-img-send-con">
                            <div className="btn-send-bot" onClick={handleSubmit}>
                                <IoIosArrowBack size={25} />
                            </div>
                            <label>
                                <IoAttach className="icon" size={25} />
                                <input type="file" onChange={(e) => handleImage(e)} />
                            </label>
                            <input type="text" placeholder="رد" onChange={(e) => setMessage(e.target.value)} value={message} />
                        </div>
                    </form>
                </div>
                <div className="suggistions">
                    <p>كيف اصمم هوية بصرية جديدة لمنتجاتي</p>
                    <p>كيف اصمم هوية بصرية جديدة لمنتجاتي</p>
                    <p>كيف اصمم هوية بصرية جديدة لمنتجاتي</p>
                    <p>كيف اصمم هوية بصرية جديدة لمنتجاتي</p>
                    <p>كيف اصمم هوية بصرية جديدة لمنتجاتي</p>
                    <p>كيف اصمم هوية بصرية جديدة لمنتجاتي</p>
                </div>
            </form>
        </>
    )
}

export default Questions