import "./Abilities.css"
import { motion } from "framer-motion"
import Stars from "../../../Assets/Images/Home/stars.svg"
import poster from "../../../Assets/Images/Home/poster.svg"
// import video from "../../../Assets/Images/Home/n.mp4"
import VideoPlayer from "react-video-js-player"
import { fadeUp } from "../../../Utils/Motions/motion"
const Abilities = () => {
    return (
        <div className="container-fluid video-section">
            <motion.div
                variants={fadeUp}
                initial="hidden"
                transition={{
                    duration: 3.5
                }}
                whileInView="visible"
                className="video-text-con">
                <div className="spirtkles-con">
                    <div className="icon-spirtkles-con">
                        <img src={Stars} alt="stars-icon" />
                    </div>
                </div>
                <div className="text">
                    <h2>تعرف أكثر على قدرات محرك داعم</h2>
                    <p>مدعوم بلوحة تحكم تتضمن ميزات اولى من نوعها</p>
                </div>
                <div className="flex-aswad-center">
                    <VideoPlayer
                        // src={video}
                        poster={poster}
                    />
                </div>
            </motion.div>
        </div>
    )
}

export default Abilities