
const getLoadingText = (loading, text) => {
    if (loading == null) {
        return text.null
    }
    else if (loading == true) {
        return text.success
    }
    else if (loading == false) {
        return text.faild
    }
    else {
        return text.loading
    }
}

export default getLoadingText