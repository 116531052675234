import "./DepartmentsCard.css"

const DepartmentsCard = ({ item, onClick }) => {
    return (
        <div
            onClick={onClick}
            className="dipartment-widget-one dipartment-widget-three" key={item.id}>
            <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.30714 20C1.65 20 1.10143 19.78 0.661429 19.34C0.220476 18.899 0 18.35 0 17.6929V2.30714C0 1.65 0.220476 1.10143 0.661429 0.661428C1.10143 0.220476 1.65 0 2.30714 0H9.42286L12.28 2.85714H23.4071C24.0643 2.85714 24.6129 3.07762 25.0529 3.51857C25.4938 3.95857 25.7143 4.50714 25.7143 5.16429V17.6929C25.7143 18.35 25.4943 18.8986 25.0543 19.3386C24.6133 19.7795 24.0643 20 23.4071 20H2.30714Z" fill="#E83090" />
            </svg>
            <p className="m-0"> {item.name}</p>
            <h6>({item.count} اعضاء)</h6>
        </div>
    )
}

export default DepartmentsCard