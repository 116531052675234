import { fadeBottom, fadeLeft, fadeRight } from "../../../../Utils/Motions/motion"

const YourNextStep = [
    {
        id: 1,
        text: "تقارير شهرية",
        animation: fadeLeft
    },
    {
        id: 2,
        text: "تقارير شهرية",
        animation: fadeBottom
    }
    , {
        id: 3,
        text: "واجهات بسيطة",
        animation: fadeRight
    }
]
export default YourNextStep