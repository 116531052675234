import "./BlueGlassCon.css"

const BlueGlassCon = ({ children, additionalClassName }) => {
    return (
        <div className={`blue-glass-con ${additionalClassName}`}>
            {children}
        </div>
    )
}

export default BlueGlassCon