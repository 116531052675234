import Logo from "../../Images/Dashboard/Group 16.svg"
import Vector from "../../Images/Dashboard/Group.svg"
import Vector2 from "../../Images/Dashboard/Vector.svg"
import Vector3 from "../../Images/Dashboard/Vector3.svg"
import Vector4 from "../../Images/AdminDashboard/Vector.svg"
const AdminLinks = [
    {
        id: 1,
        liClassName: "li",
        text: "لوحة التحكم",
        path: "/AdminPanel",
        icon: Vector4
    },
    {
        id: 2,
        liClassName: "li",
        text: "لوحة داعم",
        path: "Questions",
        icon: Logo,
        name: "use_da3em",
        use_da3em: true
    },
    {
        id: 3,
        liClassName: "li",
        text: "الملف الشخصي",
        path: "EditProfile",
        icon: Vector
    },
    {
        id: 4,
        liClassName: "li",
        text: "التيكيت",
        classSecondNav: "second-ul admin-second-ul",
        isTicket: [
            {
                id: 1,
                text: "تيكت مفتوحة",
                status: "open"
            },
            {
                id: 2,
                text: "تيكت قيد المتابعة",
                status: "in_progress"
            },
            {
                id: 3,
                text: "تيكت مغلقة",
                status: "closed"
            }
        ],
        path: "tickets",
        icon: Vector2,
        name: "use_ticket",
        use_ticket: true
    },
    {
        id: 5,
        liClassName: "li",
        text: "سجل البحث",
        path: "SearchHistory",
        icon: Vector3,
        name: "use_da3em",
        use_da3em: true
    },
    {
        id: 6,
        liClassName: "li",
        text: "الاعضاء",
        path: "members",
        icon: Vector3
    },
    {
        id: 7,
        liClassName: "li",
        text: "الاقسام",
        path: "departments",
        icon: Vector3,
        name: "create_section",
        create_section: true
    },
    {
        id: 8,
        liClassName: "li",
        text: "الصلاحيات",
        path: "SelectPermissions",
        control_permissions: true,
        name: "control_permissions",
        icon: Vector3
    },
    {
        id: 9,
        liClassName: "li",
        text: "تقارير الاعضاء",
        path: "MembersReports",
        icon: Vector3
    },



]
export default AdminLinks