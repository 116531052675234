import { useEffect, useState } from "react"
import "./ParticleContainer.css"
import { useLocation } from "react-router-dom"
const ParticleContainer = ({ className, children }) => {
    const [isFullHeight, setIsFullHeight] = useState(false)
    const location = useLocation()
    useEffect(() => {
        if (location.pathname == "/SignUp" || location.pathname == "/SignUp/CompanyStep") {
            setIsFullHeight(true)
        }
        else {
            setIsFullHeight(false)
        }
    }, [location.pathname])
    return (
        <div className={`container-fluid sign-up-con ${className} ${isFullHeight && "h100"}`}>
            {children}
        </div>
    )
}

export default ParticleContainer