const SearchHistoryData = [

    {
        title: 'الرقم',
        dataIndex: 'conversation_id',
        key: 'conversation_id',
    },
    {
        title: 'العنوان',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'التاريخ',
        dataIndex: 'start_time',
        key: 'start_time',
    },
    {
        title: <svg width="39" height="21" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="39" height="3" rx="1.5" fill="white" />
            <rect y="9" width="28" height="3" rx="1.5" fill="white" />
            <rect y="18" width="16" height="3" rx="1.5" fill="white" />
        </svg>,
        dataIndex: 'conversation_id',
        key: 'conversation_id',
        isBtn: true
    }
]
export default SearchHistoryData