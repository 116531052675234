import "./DashboardBtn.css"

const DashboardBtn = ({ onClick, children }) => {
    return (
        <div className="send-invite">
            <a className="invite-btn" onClick={onClick}>{children}</a>
        </div>
    )
}

export default DashboardBtn