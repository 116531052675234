const inputs = [
    { // First Row
        id: 1,
        row: [
            {

                id: 1,
                className: "form-control",
                isInput: true,
                name: "full_name",
                type: "text",
                label: "الاسم الكامل"
            },
            {
                id: 2,
                isSelect: true,
                className: "form-control form-select",
                name: "is_active",
                label: "حالة المستخدم"
            },
        ]
    },
    { // second Row
        id: 2,
        row: [
            {

                id: 1,
                className: "form-control",
                isInput: true,
                name: "career",
                type: "text",
                label: "المهنة"
            },
            {

                id: 2,
                className: "form-control",
                isInput: true,
                name: "job_title",
                type: "text",
                label: "الوظيفة"
            },
        ]
    },
]
export default inputs