import { useState } from "react"
import "./StepOne.css"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { getUserType } from "../../../redux/userDetailsSlice"
import { Select, BtnWithCon, Steps } from "../../../Components"
import { stepOne } from "../../../Assets/Data"
import usePath from "../../../Custom Hooks/usePath"
import { motion } from "framer-motion"
import { SignUpMotion } from "../../../Utils/Motions/motion"


const StepOne = () => {
    const [selectedType, setSelectedType] = useState()
    const [user_type, setUserType] = useState()
    const dispatch = useDispatch()
    const handleNavigate = usePath(() => {
        window.sessionStorage.setItem("user_type", user_type)
    }
    )

    const handleClick = (item) => {
        setSelectedType(item.id)
        setUserType(item.userType)
    }
    const selectBoxes = stepOne.map(item => (
        <Select
            key={item.id}
            title={item.title}
            onClick={() => handleClick(item)}
            selectedType={selectedType}
            selectedClass={item.className}
            isSelected={item.id == selectedType} />
    ))
    return (
        <motion.div
            className="zIndex"
            variants={SignUpMotion}
            initial="initial"
            animate="animate"
            exit="initial"
            transition={{
                duration: .7
            }}
        >
            <div className="steps-one">
                <Steps count={2} />
            </div>
            <div className="select-sign-up">
                {selectBoxes}
            </div>
            <div className="flex-aswad-center">
                <BtnWithCon
                    buttonContainerClass="next-con"
                    buttonClassName="next"
                    handleSubmit={() => handleNavigate("/")}
                    text="الرئيسية" />
                <BtnWithCon
                    buttonContainerClass="next-con"
                    buttonClassName="next"
                    handleSubmit={() => handleNavigate(user_type == "individual" ? "NormalUser" :
                        "CompanyStep")}
                    text="التالي" />
            </div>
        </motion.div>
    )
}

export default StepOne
