import "./Comment.css"

const Comment = ({ description, text, conClassName, pClassName }) => {
    return (
        <div className={conClassName}>
            <p className={pClassName}>{text}</p>
            <h6>{description}</h6>
            {text == "انت" ? <div className="hr"></div> : <></>}
        </div>
    )
}

export default Comment