import "./DashboardNav.css"
import Cookies from "js-cookies"
import SubLogo from "../../Assets/Images/logo.svg"
import Burger from "../../Assets/Images/AdminDashboard/Burger.svg"
import { useDispatch } from "react-redux"
import { munuChangeStatus } from "../../redux/StylesSlice"
import { HeaderLinks } from "../../Assets/Data"
import { CheckLinksRoles } from "../../Components"
import { Fragment, useState } from "react"
import { usePath } from "../../Custom Hooks"
const DashboardNav = () => {
    const [isScrolled, setIsScrolled] = useState(false)
    const handleNavigate = usePath()
    const dispatch = useDispatch()
    const links = HeaderLinks.map(link => (
        <Fragment key={link.id}>
            <CheckLinksRoles link={link} />
        </Fragment>
    ))
    window.addEventListener("scroll", () => {
        if (window.scrollY > 50) {
            setIsScrolled(true)
        }
        else {
            setIsScrolled(false)
        }
    })
    return (
        <div className={`sub-nav ${isScrolled == true && "scroll-nav"}`}>
            <div className="burger-admin-icon d-block d-md-none" onClick={() => dispatch(munuChangeStatus())}>
                <img src={Burger} />
            </div>
            <div className="logo-sub-nav">
                <img src={SubLogo} />
                <p className="m-0">داعم</p>
            </div>
            <a className="sign-out-btn"
                onClick={() => handleNavigate("/")}
            >الرئيسية</a>
        </div>
    )
}

export default DashboardNav