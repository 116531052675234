import { Table } from "antd"
import "./TableCon.css"

const TableCon = ({ columns, dataSource, loading, onChange, conClassName, additionalElement, pageSize, totalPages }) => {
    return (
        <div className={conClassName}>
            <Table columns={columns} dataSource={dataSource} align="center"
                rowKey={record => record.id}
                loading={loading}
                pagination={{
                    pageSize: pageSize,
                    total: totalPages * pageSize,
                    onChange: onChange
                }}
            />
            {additionalElement && additionalElement}
        </div>
    )
}

export default TableCon