import { BtnWithoutCon } from "../../../Components"
import "./Form.css"
import { usePath, useTestPost } from "../../../Custom Hooks"
import ForgotPasswordService from "../../../Services/ForgotPasswordService"
import { useCallback } from "react"
import { getLoadingText } from "../../../Helpers"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import ForgotPassword from "../../../Utils/ValidtionsSchemas/SignIn/ForgotPassword"
import { Tooltip } from "antd"
const Form = () => {
    const { register, handleSubmit, formState: {
        errors
    } } = useForm({
        mode: "onBlur",
        resolver: zodResolver(ForgotPassword)
    })
    const handleNavigate = usePath()
    const [loading, postFunc] = useTestPost(ForgotPasswordService.post, () => handleNavigate("/ValidCode"))
    const text = getLoadingText(loading, {
        null: "ارسال",
        success: "تم ارسال الرسالة بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    const onSubmit = useCallback((data) => {
        postFunc(data)
    }, [postFunc])
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Tooltip placement="top" title={errors.email?.message}>
                <input
                    {...register("email")}
                    type="email"
                    placeholder="البريد الالكتروني" />
            </Tooltip>
            <h5> سيتم ارسال رمز تأكيد الى صندوق رسائل بريدك الالكتروني</h5>
            <BtnWithoutCon className="sign-in-btn"
                text={text} />
        </form>
    )
}

export default Form