import { useState } from "react";
import "./Tickets.css"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosAttach } from "react-icons/io";
import { Base64, DynamicInputs, getLoadingText } from "../../../Helpers";
import { TicketsData, TicketsInputs } from "../../../Assets/Data";
import { CreateTicketsPanner, TableCon } from "../../../Components";
import { useForm } from "react-hook-form";
import { useTestGet, useTestPost } from "../../../Custom Hooks";
import TicketsService from "../../../Services/TicketsService";
const Tickets = () => {
    const { register, handleSubmit, formState: {
        errors
    } } = useForm()
    const navigate = useNavigate()
    const [page, setPage] = useState(1)
    const [file, setFile] = useState()
    const status = useSelector(state => state.userDetailsSlice.status)
    const [dataSource, loading] = useTestGet(TicketsService.getAllTickets(page, status), {
        page,
        status
    })
    const [loading1, postFunc] = useTestPost(TicketsService.createTicket, () => {
        window.location.reload()
    })
    const onSubmit = (data) => {

        if (file != null) {
            postFunc({ ...data, file })
        }
        else {
            postFunc({ ...data })
        }
    }
    const text = getLoadingText(loading1, {
        null: "ارسال",
        success: "تم انشاء التيكيت بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })

    const handleNavigate = (id) => {
        navigate(`./SingleTicket/${id}`)
    }
    const ticketsCol = TicketsData.map(item => {
        if (item.isBtn) {
            item.render = (record) => (
                <a className="table-details" onClick={() => handleNavigate(record)}>
                    تفاصيل</a>
            )
            return item
        }
        else {
            return item
        }
    })
    const handleImage = async (e) => {
        const file = e.target.files[0]
        const base64 = await Base64(file)
        setFile(base64)
    }
    const inputs = TicketsInputs.map(row => (
        <div className={row.rowClassName} key={row.id}>
            {row.row.map(col => (<>
                <label htmlFor={col.name} className={col.labelClassName}>{col.label}</label>
                <div className={col.colClassName}>
                    <DynamicInputs
                        profileImageDetails={{
                            handleChange: handleImage,
                            icon: <IoIosAttach size={30} />,
                        }}
                        input={col}
                        register={register(col.name)}
                        errors={errors}
                    />
                </div>
            </>))}
        </div>
    ))
    return (
        <>
            <div className="tickets-con">
                <CreateTicketsPanner
                    text="هل تواجه مشكلة؟ أنشئ تيكت جديد"
                    btnText="انشاء"
                    Modal={{
                        isModal: true,
                        handleSubmit: handleSubmit(onSubmit),
                        inputs,
                        text
                    }}
                />
                <TableCon columns={ticketsCol}
                    dataSource={dataSource?.tickets}
                    loading={loading}
                    onChange={(page) => setPage(page)}
                    conClassName="table-con"
                    pageSize={6}
                    totalPages={dataSource?.pages}
                />
            </div>
        </>
    )
}

export default Tickets