import "./Card.css"
import { motion } from "framer-motion"
const Card = ({ variants, title, description, className }) => {
    return (
        <motion.div
            variants={variants}
            transition={{
                duration: .5,
                delay: .7
            }}
            initial="hidden"
            whileInView="visible"
            className={`card ${className}`}>
            <div className="card-text">
                <h3>{title}</h3>
                <p>
                    {description}
                </p>
            </div>
        </motion.div>
    )
}

export default Card