import { cardMotion } from "../../../../Utils/Motions/motion"
import { motion } from "framer-motion"
import "./Card.css"

const Card = ({ title, price, delay, key }) => {
    return (
        <motion.div
            key={key}
            variants={cardMotion}
            exit="exit"
            animate="animate"
            initial="initial"
            transition={{
                duration: 1,
                delay
            }}
            className="card-join-us">
            <div className="card-join-header">
                <h4>{title}</h4>
                <h5>{price}$</h5>
                <p>ريال سعودي شهريا</p>
                <ul>
                    <li>نص يمثل ميزة عن الباقة</li>
                    <li>نص يمثل ميزة عن الباقة</li>
                    <li>نص يمثل ميزة عن الباقة</li>
                </ul>
                <a className="join-us-btn">اشترك</a>
            </div>
        </motion.div>
    )
}

export default Card