import { Outlet } from "react-router-dom"
import "./AdminPanel.css"
import { DashboardNav, SideNav } from "../../../Sections"
import { AdminLinks } from "../../../Assets/Data"
import { DashboardLayout } from "../../../Components"
const AdminPanel = () => {
    return (
        <>
            <DashboardLayout>
                <div className='row'>
                    <SideNav linksArray={AdminLinks} className="side-nav admin-side-nav" userType="admin" />
                    <div className='col-md-9'>
                        <DashboardNav />
                        <Outlet />
                    </div>
                </div>
            </DashboardLayout>
        </>
    )
}

export default AdminPanel