import { message } from "antd";
import { z } from "zod";
const schema = z.object({
    email: z.string().email("الرجاء ادخال بريد الكتروني فعال"),
    full_name: z.string().min(1, "على الاقل محرف واحد"),
    username: z.string().min(1, "على الاقل محرف واحد"),
    company_name: z.string().min(1, "على الاقل محرف واحد"),
    phone_number: z.string().refine(value => {
        const parsedValue = parseInt(value, 10);
        return !isNaN(parsedValue) && parsedValue > 0;
    }, {
        message: "الرجاء ادخال رقم هاتف صحيح",
    }),
    password: z.string().min(8, "Password must be at least 8 characters long"),
    confirm_password: z.string().min(8, "Password must be at least 8 characters long"),
    career: z.string().min(1, "على الاقل محرف واحد"),
    job_title: z.string().refine(value => value != null, {
        message: "faild"
    }),
    company_code: z.string().min(8, "Password must be at least 8 characters long"),
}).refine((data) => data.password == data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirm_password"], // path of error
});
export default schema