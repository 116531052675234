const editProfileArr = [
    {
        id: 1,
        row: [
            {
                id: 1,
                isInput: true,
                type: "text",
                placeholder: "الاسم الكامل",
                name: "full_name"
            },
            {
                id: 2,
                isInput: true,
                type: "text",
                placeholder: "رقم الموبايل",
                name: "phone_number"
            },
        ]
    },
    {
        id: 2,
        row: [
            {
                id: 1,
                isInput: true,
                className: "form-control",
                name: "career",
                type: "text",
                placeholder: "المهنة",
            },
            {
                id: 2,
                isInput: true,
                className: "form-control",
                name: "old_password",
                type: "text",
                placeholder: "كلمة المرور القديمة"
            },


        ]
    },
    {
        id: 3,
        row: [
            {
                id: 2,
                isInput: true,
                className: "form-control",
                name: "password",
                type: "password",
                placeholder: "كلمة المرور"
            },
            {
                id: 1,
                isInput: true,
                className: "form-control",
                name: "password2",
                type: "text",
                placeholder: "تأكيد كلمة المرور",
            }

        ]
    }
]
export default editProfileArr