import "./BtnWithoutCon.css"

const BtnWithoutCon = ({ className, text }) => {
    return (
        <button className={className}>
            {text}
        </button>
    )
}

export default BtnWithoutCon