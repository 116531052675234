import "./ChatBox.css"

const ChatBox = ({ children }) => {
    return (
        <div className="chat-loader-con">
            <div className="tikect-chat-con row">
                {children}
            </div>
        </div>
    )
}

export default ChatBox