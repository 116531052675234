const AdminPermissionsData = [
    {
        id: 1,
        label: "تحديد الصلاحيات",
        name: "control_permissions"
    },
    {
        id: 2,
        label: "انشاء قسم",
        name: "create_section"
    },
    {
        id: 3,
        label: "استخدام داعم",
        name: "use_da3em"
    }
    ,
    {
        id: 4,
        label: "التيكت",
        name: "use_ticket"
    }
]
export default AdminPermissionsData