import { useContext, useState } from "react"
import { TokenContext } from "../../Context/TokenContext"
import { Navigate, Outlet } from "react-router-dom"
import Cookies from "js-cookies"
const PrivateTickectRoute = () => {
    const token = useContext(TokenContext)
    const [permissions, setPermissions] = useState(JSON.parse(Cookies.getItem("permissions")))
    return (
        <>
            {
                token != null && permissions.use_ticket == true ? <Outlet /> : <Navigate to="/AdminPanel" />
            }
        </>
    )
}

export default PrivateTickectRoute