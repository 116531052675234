import "./ValidCode.css"
import { AccountActionTitle, GlassContainer, Particle, ParticleContainer, SignInBtn } from "../../Components"
import { ValidCodeForm } from "../../Sections"
const ValidCode = () => {
    return (
        <ParticleContainer className="sign-in linear-background reset-password">
            <Particle />
            <GlassContainer className="glass-con valid-glass">
                <AccountActionTitle className="h2-sign-in" text="اعادة تعيين كلمة المرور" />
                <ValidCodeForm />
            </GlassContainer>
        </ParticleContainer>
    )
}

export default ValidCode