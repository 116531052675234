
import { createSlice } from "@reduxjs/toolkit";
const PermissionsSlice = createSlice({
    name: "PermissionsSlice",
    initialState: {
        permissions: {}
    },
    reducers: {
        setPermissions: (state, action) => {
            state.permissions = action.payload.permissions
        }
    },

})
const { actions, reducer } = PermissionsSlice;
export const { setPermissions } = actions;
export default reducer