
import "./SignIn.css"
import { AccountActionTitle, GlassContainer, Particle, ParticleContainer } from "../../Components"
import { SignInForm } from "../../Sections"
const SignIn = () => {
    return (
        <>
            <ParticleContainer className="sign-in linear-background">
                <Particle />
                <GlassContainer className="glass-con">
                    <AccountActionTitle className="h2-sign-in" text="تسجيل الدخول" />
                    <h4>مرحبا بك في داعم</h4>
                    <SignInForm />
                </GlassContainer>
            </ParticleContainer>
        </>
    )
}

export default SignIn