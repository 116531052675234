import "./NormalUser.css"
import { Steps } from "../../../Components"
import { Form } from "../../"
import schema from "../../../Utils/ValidtionsSchemas/SignUp/NormalUser"
import { motion } from "framer-motion"
import { SignUpMotion } from "../../../Utils/Motions/motion"
import NormalUserLast from "../../../Assets/Data/SignUp/NormalUserLast"
const NormalUser = () => {

    return (
        <motion.div
            variants={SignUpMotion}
            initial="initial"
            animate="animate"
            exit="initial"
            className="zIndex"
            transition={{
                duration: .7
            }}>
            <div className="steps-one sign-up-form-one">
                <Steps count={2} />
            </div>
            <Form
                formArray={NormalUserLast}
                schema={schema}
                length={4}
                className="sign-up-form"
                buttonClassName="next mt-1"
                buttonContainerClass="next-con next-normal-user"
                isRandomCode={false}
            />
        </motion.div>
    )
}

export default NormalUser