import { usePath } from "../../../../Custom Hooks"
import "./Widget.css"

const Widget = ({ icon, textOne, textTwo, isBtn }) => {
    const handleNavigate = usePath()
    return (
        <div className="widget-one">
            {icon}
            <p className={`m-0 ${isBtn == true && "last-widget-p"}`}>
                {textOne}
            </p>
            <h6>
                {textTwo}
            </h6>
            {isBtn == true ? <div className="upgrade-btn-con">
                <a className="upgrade" onClick={() => handleNavigate("/Supsucriptions")}>تطوير</a>
            </div> : <></>}
        </div>
    )
}

export default Widget