import "./GlassContainer.css"

const GlassContainer = ({ className, children }) => {
    return (
        <>
            <div className={className}>
                {children}
            </div>
        </>
    )
}

export default GlassContainer