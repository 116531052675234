import { AuthAxios, PublicAxios } from "./axios_handler"

class PaymentService {
    static endPoint = "authentications/api/payment"
    static post = (body) => {
        return AuthAxios.post(`${PaymentService.endPoint}/`, body)
    }
    static get = (orderId) => {
        return PublicAxios.get(`${PaymentService.endPoint}_success/?order_id=${orderId}`)
    }
}
export default PaymentService