import { LiaWpforms } from "react-icons/lia";
import { FaUser, FaLock } from "react-icons/fa";
import { MdOutlinePhoneIphone, MdOutlineWorkOutline, MdHomeWork } from "react-icons/md";
import { IoIosMail } from "react-icons/io";

const NormalUserLast = [
    {
        // first Row 
        id: 1,
        information: "photo",
        row: [
            {
                id: 1,
                isProfileFile: true,
                className: "form-control",
                labelClassName: "upload-lable-profile",
                iconClassName: "upload-con-profile",
                name: "image",
            },
        ]


    },

    { // second Row
        id: 2,
        information: "personal",
        row: [
            {

                id: 1,
                className: "form-control",
                isInput: true,
                name: "full_name",
                type: "text",
                placeholder: "الاسم الكامل",
                groupIcon: <LiaWpforms size={30} className="icon" />,
            },
            {
                id: 2,
                isInput: true,
                className: "form-control",
                name: "username",
                type: "text",
                placeholder: "اسم المستخدم",
                groupIcon: <FaUser size={30} className="icon" />,
            },
        ]
    },
    {
        // third Row
        id: 3,
        information: "personal",
        row: [
            {
                id: 1,
                isInput: true,
                className: "form-control",
                name: "phone_number",
                type: "text",
                placeholder: "رقم الموبايل",
                groupIcon: <MdOutlinePhoneIphone size={30} className="icon" />,
            },
            {
                id: 2,
                isInput: true,
                className: "form-control",
                name: "email",
                type: "email",
                placeholder: "البريد الاكتروني",
                groupIcon: <IoIosMail size={30} className="icon" />,
            },
        ]
    },
    {
        // forth Row
        id: 4,
        information: "personal",
        row: [
            {
                id: 1,
                isInput: true,
                className: "form-control",
                name: "confirm_password",
                type: "text",
                placeholder: "تأكيد كلمة المرور",
                groupIcon: <FaLock size={30} className="icon" />,
            },
            {
                id: 2,
                isInput: true,
                className: "form-control",
                name: "password",
                type: "password",
                placeholder: "كلمة المرور",
                groupIcon: <FaLock size={30} className="icon" />,
            },
        ]
    },
    {
        // fifth Row
        id: 5,
        information: "job",
        row: [
            {
                id: 1,
                isInput: true,
                className: "form-control",
                name: "career",
                type: "text",
                placeholder: "المهنة",
                groupIcon: <MdOutlineWorkOutline size={30} className="icon" />
            },
            {
                id: 2,
                isSelect: true,
                className: "form-control form-select",
                name: "job_title",
                defaultOption: <option value="الوظيفة" selected disabled hidden>الوظيفة</option>,
                groupIcon: <MdHomeWork size={30} className="icon" />
            },
        ]
    },

]
export default NormalUserLast