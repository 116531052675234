import React, { useCallback } from 'react'
import { Inputs } from '../../../Assets/Data'
import { Link } from 'react-router-dom'
import { BtnWithoutCon } from '../../../Components'
import SignInService from "../../../Services/SignInService"
import { DynamicInputs, getLoadingText } from "../../../Helpers"
import logo from "../../../Assets/Images/SignIn/logo.svg"
import Cookies from "js-cookies"
import { useTestPost } from '../../../Custom Hooks'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import SignInSchema from '../../../Utils/ValidtionsSchemas/SignIn/SignInSchema'
const SignInForm = () => {
    const { register, handleSubmit, formState: {
        errors
    } } = useForm({
        mode: "onBlur",
        resolver: zodResolver(SignInSchema)
    })
    const [loading, postFunc, , error] = useTestPost(SignInService.post, res => {
        Cookies.setItem("token", res.data.token)
        Cookies.setItem("userId", res.data.user.id)
        Cookies.setItem("userType", res.data.user_type)
        setTimeout(() => {
            window.location.assign("/")
        }, 1000)
    })
    const inputs = Inputs.map(input => <DynamicInputs
        errors={errors}
        register={register(input.name)}
        input={input}
    />)
    const onSubmit = useCallback((data) => {
        postFunc(data)
    }, [postFunc])
    const text = getLoadingText(loading, {
        null: " تسجيل الدخول",
        success: "تم تسجيل الدخول بنجاح",
        faild: error,
        loading: "الرجاء الانتظار"
    })
    return (
        <form onSubmit={handleSubmit(onSubmit)} className="zIndex">
            {inputs}
            <p>
                <Link to="/ForgotPassword">
                    نسيت كلمة المرور؟
                </Link>
            </p>
            <BtnWithoutCon className="sign-in-btn"
                text={text} />
            <div className="join-us-sign-in">
                ليس لديك حساب بعد؟! <Link to="/SignUp">
                    انضم الى داعم!
                </Link>
                <img src={logo} alt='logo' />
            </div>
        </form>
    )
}

export default SignInForm
