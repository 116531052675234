const MembersReportsCols = [
    {
        title: 'الرقم',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'الاسم',
        dataIndex: 'username',
        key: 'username',
    },
    {
        title: 'المشكلة',
        dataIndex: 'title',
        key: 'title',
    },

    {
        title: "النتيجة",
        dataIndex: "like",
        key: "like",
        render: (record) => (
            <div className="flex-aswad-center">
                <div className="circle-table-like" style={{
                    background: record == true ? "rgba(15, 89, 213, 1)" : "rgba(255, 70, 70, 1)"
                }}></div>
            </div>
        )
    }
]
export default MembersReportsCols