import NormalUserLast from "./NormalUserLast"
import { IoIosAttach } from "react-icons/io";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
const existCompany = [
    ...NormalUserLast,
    {
        // sixth Row
        id: 6,
        information: "job",
        row: [
            {
                id: 1,
                isInput: true,
                className: "form-control",
                name: "company_code",
                type: "text",
                isExist: true,
                placeholder: "كود الشركة",
                groupIcon: <GiPerspectiveDiceSixFacesRandom size={30} className="icon" />
            },
        ]
    },
]
export default existCompany