const Inputs = [
    {
        id: 1,
        isInput: true,
        type: "text",
        placeholder: "البريد الالكتروني او اسم المستخدم",
        name: "username"
    },
    {
        id: 2,
        isInput: true,
        type: "password",
        placeholder: "كلمة المرور",
        name: "password"
    }
]
export default Inputs