const WidgetData = [
    {
        id: 1,
        icon: <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="3.5" y="1.5" width="39" height="39" rx="4.5" stroke="url(#paint0_linear_0_1)" stroke-opacity="0.71" />
            <g filter="url(#filter0_d_0_1)">
                <path d="M22.5 20.5C25.4006 20.5 27.75 18.1506 27.75 15.25C27.75 12.3494 25.4006 10 22.5 10C19.5994 10 17.25 12.3494 17.25 15.25C17.25 18.1506 19.5994 20.5 22.5 20.5ZM22.5 23.125C18.9956 23.125 12 24.8837 12 28.375V29.6875C12 30.4094 12.5906 31 13.3125 31H31.6875C32.4094 31 33 30.4094 33 29.6875V28.375C33 24.8837 26.0044 23.125 22.5 23.125Z" fill="#0F59D5" />
            </g>
            <defs>
                <filter id="filter0_d_0_1" x="0.9" y="0.9" width="43.2" height="43.2" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="5.55" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.2625 0 0 0 0 0.538131 0 0 0 0 1 0 0 0 0.24 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_0_1" x1="43" y1="0.999998" x2="3" y2="41" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0.28" />
                </linearGradient>
            </defs>
        </svg>,
        textOne: "عدد الأعضاء المسجلين",
        // textTwo: <>13/20 <span>(7 أعضاء متبقي)</span></>
    },
    {
        id: 2,
        icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="4.5" stroke="url(#paint0_linear_0_1)" stroke-opacity="0.71" />
            <path d="M17.0254 26.875L16.3379 28.25H10V9H26.5V21.665L25.125 18.915V10.375H11.375V26.875H17.0254ZM14.125 18.625H12.75V17.25H14.125V18.625ZM21.1504 18.625H15.5V17.25H21.8379L21.1504 18.625ZM12.75 21.375H14.125V22.75H12.75V21.375ZM15.5 21.375H19.7754L19.0879 22.75H15.5V21.375ZM14.125 14.5H12.75V13.125H14.125V14.5ZM23.75 14.5H15.5V13.125H23.75V14.5ZM23.75 24.125V27.5625H22.375V24.125H23.75ZM22.375 28.25H23.75V29.625H22.375V28.25ZM29.25 31H16.875L23.0625 18.625L29.25 31ZM18.8193 29.7969H27.3057L23.0625 21.3105L18.8193 29.7969Z" fill="#0F59D5" />
            <defs>
                <linearGradient id="paint0_linear_0_1" x1="40" y1="-2.38419e-06" x2="2.38419e-06" y2="40" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0.28" />
                </linearGradient>
            </defs>
        </svg>,
        textOne: "المشكلات التي حلها داعم",
        // textTwo: `430 مشكلة (نوفمبر)`
    },
    {
        id: 3,
        icon: <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="39" height="39" rx="4.5" stroke="url(#paint0_linear_0_1)" stroke-opacity="0.71" />
            <path d="M13 31V28.25H26.75V31H13ZM18.5 25.5V14.2594L14.925 17.8L13 15.875L19.875 9L26.75 15.875L24.825 17.8L21.25 14.2594V25.5H18.5Z" fill="#0F59D5" />
            <defs>
                <linearGradient id="paint0_linear_0_1" x1="40" y1="-2.38419e-06" x2="2.38419e-06" y2="40" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0.28" />
                </linearGradient>
            </defs>
        </svg>,
        textOne: "تطوير باقة الاشتراك",
        textTwo: `قم بتطوير باقة اشتراكك وتمتع بميزات اكبر`,
        isBtn: true
    }
]
export default WidgetData