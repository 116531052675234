import React from 'react'
import "./Select.css"
const Select = ({ title, onClick, selectedClass, isSelected }) => {
    return (
        <div className={selectedClass + " " + "box-sign-up"} onClick={onClick}>
            <svg
                className={isSelected == true ? `visible` : "not-visible "}
                xmlns="http://www.w3.org/2000/svg" width="50" height="55" viewBox="0 0 52 58" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M51.5 12C51.5 5.37258 46.1274 0 39.5 0H0L51.5 58V12ZM35.5 28C41.0228 28 45.5 23.5228 45.5 18C45.5 12.4772 41.0228 8 35.5 8C29.9772 8 25.5 12.4772 25.5 18C25.5 23.5228 29.9772 28 35.5 28Z" fill="white" />
                <path d="M41.5 14L33.5 22L29.5 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="55" viewBox="0 0 53 58" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M53 12C53 5.37258 47.6274 0 41 0H0L53 58V12ZM37 28C42.5228 28 47 23.5228 47 18C47 12.4772 42.5228 8 37 8C31.4772 8 27 12.4772 27 18C27 23.5228 31.4772 28 37 28Z" fill="white" fillOpacity="0.4" />
            </svg>
            <p>
                {title}
            </p>
        </div>
    )
}

export default Select