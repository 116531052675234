const stepOne = [
    {
        id: 1,
        title: "تسجيل الدخول كمستخدم ",
        userType: "individual",
        className: "select1"
    },
    {
        id: 2,
        title: " انشاء حساب في شركة ",
        userType: "company",
        className: "select2"
    },
]
export default stepOne