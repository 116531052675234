import "./Supsucriptions.css"
import { Header } from "../../Sections"
import { useContext, useEffect, useState } from "react"
import { Card, SwitchBtn } from "../../Components"
import { useTestPost } from "../../Custom Hooks"
import SupsucriptionsService from "../../Services/SupsucriptionsService"
import PaymentService from "../../Services/PayMentService"
import { TokenContext } from "../../Context/TokenContext"
const Supsucriptions = () => {
    const token = useContext(TokenContext)
    const [status, setStatus] = useState(false)
    const [loadingCard, cardGet, data] = useTestPost(SupsucriptionsService.post)
    const [loadingPay, payPost] = useTestPost(PaymentService.post, (res) => {
        window.location.assign(res.data.order.url)
    })
    useEffect(() => {
        cardGet({
            user_type: status ? "companyemp" : "individual"
        })
    }, [status])
    const subscriptions = data && data.data.map(subscriptopn => (
        <Card
            key={subscriptopn.id}
            className="col"
            data={subscriptopn}
            onClick={() => handlePay(subscriptopn.id)}
        />
    ))
    const cardContainerStyles = {
        height: data && data.data.length > 0 ? "100%" : "50vh"
    }
    const handlePay = (Id) => {
        if (token == null) {
            window.location.assign("sign-in")
        }
        else {
            payPost({ package_id: Id })
        }

    }
    return (
        <>
            <Header />
            <div className="container-fluid Supsucriptions">
                <p>الباقات</p>
                <h2> ! اختر خطة اشتراك مناسبة لك</h2>
                <h4>قم بالانضمام لعائلة داعم وسجل دخولك قبل اتمام <br />عملية الاشتراك بباقة معينة</h4>
                <SwitchBtn
                    mainSwitchClass="switch-btn-text"
                    isClicked={status}
                    handleClick={() => setStatus(!status)}
                    switchContainerClass="switch-btn-con"
                    circleClass="circle"
                    switchChoices={{
                        first: "فردي",
                        second: "شركات"
                    }} />

                <div className="container" style={cardContainerStyles}>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                        {subscriptions}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Supsucriptions