import { memo, useContext } from "react"
import "./IsHaveToken.css"
import { TokenContext } from "../../Context/TokenContext"
const IsHaveToken = ({ AuthEle, UnAuthEle }) => {
    const token = useContext(TokenContext)
    if (token) {
        return AuthEle
    }
    else {
        return UnAuthEle
    }
}

export default memo(IsHaveToken)