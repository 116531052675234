import { AuthAxios } from "./axios_handler"
class MembersReportsService {
    static endPoint = "chatbot"
    static getStatus() {
        try {
            return AuthAxios.get(`${MembersReportsService.endPoint}/stats/`)
        }
        catch (err) {
            console.log("err")
        }
    }
    static getTableDetails(month, SectionName, like, page) {
        try {
            if (like != null && SectionName != null && month != null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        month: month,
                        section_name: SectionName,
                        like: like,
                        page
                    }
                })
            }
            else if (like != null && SectionName == null && month == null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        like: like,
                        page
                    }
                })
            }
            else if (like == null && SectionName != null && month == null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        section_name: SectionName,
                        page
                    }
                })
            }
            else if (like == null && SectionName == null && month != null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        month: month,
                        page
                    }
                })
            }
            else if (like != null && SectionName != null && month == null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        section_name: SectionName,
                        like: like,
                        page
                    }
                })
            }
            else if (like != null && SectionName == null && month != null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        like: like,
                        month: month,
                        page
                    }
                })
            }
            else if (like == null && SectionName != null && month != null) {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        month: month,
                        section_name: SectionName,
                        page
                    }
                })
            }
            else {
                return AuthAxios.get(`${MembersReportsService.endPoint}/conversation-list/`, {
                    params: {
                        page
                    }
                })
            }
        }
        catch (err) {
            console.log("err")
        }

    }
    static downloadPdf() {
        try {
            return AuthAxios.get(`${MembersReportsService.endPoint}/download-stats/current-month-pdf`)
        }
        catch (err) {
            console.log("err")
        }

    }
}
export default MembersReportsService