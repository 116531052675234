import { memo } from "react"
import "./GradientHeaderButton.css"

const GradientHeaderButton = ({ additionalClassName, onClick, text }) => {
    return (
        <a className={`gradient-btn ${additionalClassName && additionalClassName}`}
            onClick={onClick}>
            {text}
        </a>
    )
}

export default memo(GradientHeaderButton) 