const subDetails = [
    {
        id: 1,
        name: "full_name",
        label: "الاسم"
    },
    {
        id: 2,
        name: "username",
        label: "اسم المستخدم"
    },
    {
        id: 3,
        name: "job_title",
        label: "المهنة"
    },
    {
        id: 4,
        name: "career",
        label: "الوظيفة"
    }
    ,
    {
        id: 5,
        name: "phone_number",
        label: "رقم الموبايل"
    }
]
export default subDetails