import "./JoinDate.css"

const JoinDate = ({ children }) => {
    return (
        <div className="join-date-con">
            <div className="join-date">
                {children}
            </div>
        </div>
    )
}

export default JoinDate