const TicketsInputs = [
    {
        id: 1,
        rowClassName: "row input-row",
        row: [
            {
                id: 1,
                labelClassName: "col-sm-2 col-form-label",
                label: "العنوان",
                name: "title",
                isInput: true,
                type: "text",
                colClassName: "col-sm-10",
                className: "form-control"
            }
        ]
    },
    {
        id: 2,
        rowClassName: "row mb-3",
        row: [
            {
                id: 1,
                label: "الوصف",
                labelClassName: "col-sm-2 col-form-label description-label",
                name: "description",
                isTextArea: true,
                colClassName: "col-sm-10",
                className: "form-control"
            }
        ]
    },
    {
        id: 3,
        rowClassName: "row mb-3",
        row: [
            {
                id: 1,
                label: "رفع صورة",
                PhotolabelClassName: "file-upload-con",
                labelClassName: "col-sm-2 col-form-label description-label",
                isPhoto: true,
                colClassName: "col-sm-2",
            }
        ]
    }
]
export default TicketsInputs