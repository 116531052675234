const JobData = [
    {

        // Accounting and Finance 
        id: 1,
        label: "1. Accounting and Finance:",
        values: [
            {
                id: 1,
                value: "accountant",
                text: "Accountant"
            },
            {
                id: 2,
                value: "financial-analyst",
                text: "Financial Analyst"
            },
            {
                id: 3,
                value: "auditor",
                text: "Auditor"
            },
            {
                id: 4,
                value: "auditor",
                text: "Auditor"
            },
            {
                id: 5,
                value: "bookkeeper",
                text: "Bookkeeper"
            },
            {
                id: 6,
                value: "tax-consultant",
                text: "Tax Consultant"
            }
        ]
    },
    {
        // Administrative and Clerical
        id: 2,
        label: "2. Administrative and Clerical:",
        values: [
            {
                id: 1,
                value: "administrative-assistant",
                text: "Administrative Assistant"
            },
            {
                id: 2,
                value: "office-manager",
                text: "Office Manager"
            },
            {
                id: 3,
                value: "receptionist",
                text: "Receptionist"
            },
            {
                id: 4,
                value: "data-entry-clerk",
                text: "Data Entry Clerk"
            },
            {
                id: 5,
                value: "secretary",
                text: "Secretary"
            }
        ]
    },
    {
        // Customer Service
        id: 3,
        label: "3. Customer Service:",
        values: [
            {
                id: 1,
                value: "customer-support-representative",
                text: "Customer Support Representative"
            },
            {
                id: 2,
                value: "call-center-agent",
                text: "Call Center Agent"
            },
            {
                id: 3,
                value: "client-success-manager",
                text: "Client Success Manager"
            },
            {
                id: 4,
                value: "technical-support-specialist",
                text: "Technical Support Specialist"
            }
        ]
    },
    {
        // Education and Training 
        id: 4,
        label: "4. Education and Training:",
        values: [
            {
                id: 1,
                value: "teacher",
                text: "Teacher"
            },
            {
                id: 2,
                value: "professor",
                text: "Professor"
            },
            {
                id: 3,
                value: "tutor",
                text: "Tutor"
            },
            {
                id: 4,
                value: "education-coordinator",
                text: "Education Coordinator"
            }
        ]
    },
    {
        // Engineering
        id: 5,
        label: "5. Engineering:",
        values: [
            {
                id: 1,
                value: "software-engineer",
                text: "Software Engineer"
            },
            {
                id: 2,
                value: "mechanical-engineer",
                text: "Mechanical Engineer"
            },
            {
                id: 3,
                value: "civil-engineer",
                text: "Civil Engineer"
            },
            {
                id: 4,
                value: "electrical-engineer",
                text: "Electrical Engineer"
            },
            {
                id: 5,
                value: "chemical-engineer",
                text: "Chemical Engineer"
            },
            {
                id: 6,
                value: "architect-engineer",
                text: "Architect Engineer"
            }
        ]
    },
    {
        // Healthcare
        id: 6,
        label: "6. Healthcare:",
        values: [
            {
                id: 1,
                value: "doctor",
                text: "Doctor"
            },
            {
                id: 2,
                value: "nurse",
                text: "Nurse"
            },
            {
                id: 3,
                value: "pharmacist",
                text: "Pharmacist"
            },
            {
                id: 4,
                value: "medical-assistant",
                text: "Medical Assistant"
            },
            {
                id: 5,
                value: "physical-therapist",
                text: "Physical Therapist"
            }
        ]
    },
    {
        // Human Resources
        id: 7,
        label: "7. Human Resources:",
        values: [
            {
                id: 1,
                value: "hr-manager",
                text: "Hr Manager"
            },
            {
                id: 2,
                value: "recruiter",
                text: "Recruiter"
            },
            {
                id: 3,
                value: "hr-assistant",
                text: "Hr Assistant"
            },
            {
                id: 4,
                value: "training-coordinator",
                text: "Training Coordinator"
            }
        ]
    },
    {
        // Information Technology (IT)
        id: 8,
        label: "8. Information Technology (IT):",
        values: [
            {
                id: 1,
                value: "systems-administrator",
                text: "Systems Administrator"
            },
            {
                id: 2,
                value: "web-developer",
                text: "Web Developer"
            },
            {
                id: 3,
                value: "network-engineer",
                text: "Network Engineer"
            },
            {
                id: 4,
                value: "data-scientist",
                text: "Data Scientist"
            },
            {
                id: 5,
                value: "cybersecurity-analyst",
                text: "Cybersecurity Analyst"
            }
        ]
    },
    {
        // Marketing and Advertising
        id: 9,
        label: "9. Marketing and Advertising:",
        values: [
            {
                id: 1,
                value: "marketing-manager",
                text: "Marketing Manager"
            },
            {
                id: 2,
                value: "social-media-specialist",
                text: "Social Media Specialist"
            },
            {
                id: 3,
                value: "content-writer",
                text: "Content Writer"
            },
            {
                id: 4,
                value: "advertising-coordinator",
                text: "Advertising Coordinator"
            }
        ]
    },
    {
        // Sales
        id: 10,
        label: "10. Sales:",
        values: [
            {
                id: 1,
                value: "sales-representative",
                text: "Sales Representative"
            },
            {
                id: 2,
                value: "account-manager",
                text: "Account Manager"
            },
            {
                id: 3,
                value: "sales-manager",
                text: "Sales Manager"
            },
            {
                id: 4,
                value: "business-development-executive",
                text: "Business Development Executive"
            }
        ]
    },
    {
        // Operations and Logistics
        id: 11,
        label: "11. Operations and Logistics:",
        values: [
            {
                id: 1,
                value: "operations-manager",
                text: "Operations Manager"
            },
            {
                id: 2,
                value: "supply-chain-coordinator",
                text: "Supply Chain Coordinator"
            },
            {
                id: 3,
                value: "logistics-specialist",
                text: "Logistics Specialist"
            },
            {
                id: 4,
                value: "warehouse-supervisor",
                text: "Warehouse Supervisor"
            }
        ]
    },
    {
        // Creative Arts and Design
        id: 12,
        label: "12. Creative Arts and Design:",
        values: [
            {
                id: 1,
                value: "graphic-designer",
                text: "Graphic Designer"
            },
            {
                id: 2,
                value: "ui-ux-designer",
                text: "UI/UX Designer"
            },
            {
                id: 3,
                value: "illustrator",
                text: "Illustrator"
            },
            {
                id: 4,
                value: "art-director",
                text: "Art Director"
            }
        ]
    },
    {
        // Legal
        id: 13,
        label: "13. Legal:",
        values: [
            {
                id: 1,
                value: "lawyer-attorney",
                text: "Lawyer Attorney"
            },
            {
                id: 2,
                value: "paralegal",
                text: "Paralegal"
            },
            {
                id: 3,
                value: "legal-assistant",
                text: "Legal Assistant"
            },
            {
                id: 4,
                value: "legal-secretary",
                text: "Legal Secretary"
            }
        ]
    },
    {
        // Research and Development
        id: 14,
        label: "14. Research and Development:",
        values: [
            {
                id: 1,
                value: "research-scientist",
                text: "Research Scientist"
            },
            {
                id: 2,
                value: "rd-engineer",
                text: "R&D Engineer"
            },
            {
                id: 3,
                value: "lab-technician",
                text: "Lab Technician"
            }
        ]
    },
    {
        // Consulting
        id: 15,
        label: "15. Consulting:",
        values: [
            {
                id: 1,
                value: "management-consultant",
                text: "Management Consultant"
            },
            {
                id: 2,
                value: "strategy-consultant",
                text: "Strategy Consultant"
            },
            {
                id: 3,
                value: "financial-consultant",
                text: "Financial Consultant"
            }
        ]
    },
    {
        // Freelance/Self-Employed
        id: 16,
        label: "16. Freelance/Self-Employed:",
        values: [
            {
                id: 1,
                value: "freelancer",
                text: "Freelancer"
            },
            {
                id: 2,
                value: "entrepreneur",
                text: "Entrepreneur"
            },
            {
                id: 3,
                value: "small-business-owner",
                text: "Small Business Owner"
            }
        ]
    },
    {
        // Other/Unspecified
        id: 17,
        label: "17. Other/Unspecified:",
        values: [
            {
                id: 1,
                value: "other-unspecified",
                text: "Other Unspecified"
            }
        ]
    }
]
export default JobData