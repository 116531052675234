import { YourNextStepData } from "../../../Assets/Data"
import { IsHaveToken } from "../../../Components"
import { usePath } from "../../../Custom Hooks"
import { fadeBottom, fadeLeft, fadeRight, fadeUp } from "../../../Utils/Motions/motion"
import "./YourNextStep.css"
import { motion } from "framer-motion"
const YourNextStep = () => {
    const handleNavigate = usePath()
    const authElements = <IsHaveToken
        AuthEle={<></>}
        UnAuthEle={<a className="start-now-btn" onClick={() => handleNavigate("/sign-in")}>
            ابدأ الان
            <span className="blue-linear"></span>
            <span className="pink-linear"></span>
        </a>}
    />
    const lis = YourNextStepData.map(li => (
        <motion.li
            variants={li.animation}
            initial="hidden"
            whileInView="visible"
            transition={{
                duration: 1,
                delay: .5
            }}
        >
            {li.text}
        </motion.li>
    ))
    return (
        <div className="container-fluid your-next-step">
            <motion.h2
                variants={fadeUp}
                initial="hidden"
                whileInView="visible"
                transition={{
                    duration: 1
                }}
            >خطوتك التالية لتوفير وقتك وتطوير خدماتك واعمالك</motion.h2>
            <ul>
                {lis}
            </ul>
            <div className="flex-aswad-center">
                {authElements}
            </div>
        </div>
    )
}

export default YourNextStep