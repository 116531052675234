import { useState } from "react"
import { exitMotion, rotate } from "../../../../Utils/Motions/motion"
import "./RotateSwitcher.css"
import RightLeftIcon from "../../../../Assets/Images/Home/rightLeft.svg"
import { AnimatePresence, motion } from "framer-motion"
const RotateSwitcher = ({ onClick, isClicked }) => {
    return (
        <div
            onClick={onClick}
            className="rotate-switcher">
            <motion.img
                transition={{
                    duration: 1
                }}
                variants={rotate}
                initial="initial"
                animate={isClicked == true ? "tap" : "initial"}
                src={RightLeftIcon} alt="right-left-icon" />
            <AnimatePresence>
                {isClicked == true ?
                    <motion.p
                        key="persons"
                        variants={exitMotion}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="m-0">افراد</motion.p>
                    :

                    <motion.p
                        key="companies"
                        variants={exitMotion}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="m-0">شركات</motion.p>
                }
            </AnimatePresence>

        </div>
    )
}

export default RotateSwitcher