import NormalUserLast from "./NormalUserLast";
import { IoIosAttach } from "react-icons/io";
import { AiOutlineHome } from "react-icons/ai";
const newCompanyInputs = [
    ...NormalUserLast,
    {
        // sixth Row
        id: 6,
        information: "job",
        row: [
            {
                id: 1,
                className: "form-control",
                name: "company_code",
                type: "text",
                placeholder: "كود الشركة",
                isRandomCode: true
            },
            {
                id: 2,
                isInput: true,
                inlineBtn: true,
                className: "form-control",
                name: "company_name",
                type: "text",
                placeholder: "اسم الشركة",
                groupIcon: <AiOutlineHome size={30} className="icon" />
            }
        ]
    },

]
export default newCompanyInputs