import "./SideNav.css"
import { NavLink, useLocation } from "react-router-dom"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setStatusTicket } from "../../redux/userDetailsSlice"
import { FancyNavLinks, UpgradePackage } from "../../Components";
import { motion } from "framer-motion"
import { useTestGet } from "../../Custom Hooks"
import RolesService from "../../Services/RolesService"
import Cookies from "js-cookies"
const SideNav = ({ className, linksArray, userType }) => {
    const dispatch = useDispatch()
    const menuStatus = useSelector(state => state.StylesSlice.menu)
    const [data, loading] = useTestGet(RolesService.get(), null, (res) => {
        Cookies.setItem("permissions", JSON.stringify(res.data))
    })
    const [isActive, setIsActive] = useState(false)
    const location = useLocation()
    const [isDepatrmentsPage, setIsDepartmentsPage] = useState(false)
    useEffect(() => {
        if (location.pathname == "/AdminPanel") {
            setIsDepartmentsPage(true)
        }
        else {
            setIsDepartmentsPage(false)
        }
    }, [location.pathname])
    useEffect(() => {
        setIsActive(location.pathname === '/ControlPanel/Tickets' || location.pathname === '/AdminPanel/tickets');
    }, [location.pathname])
    const links = userType == "admin" && data ? linksArray.filter(item => item[item.name] == data[item.name] || item.name == undefined)
        .map(link => (
            <Fragment key={link.id}>
                <FancyNavLinks link={link} isActive={isActive} />
                {isActive == true && link.isTicket &&
                    <ul className={link.isTicket && link.classSecondNav}>
                        {link.isTicket.map(partialLink => (
                            <li key={partialLink.id} onClick={() => {
                                dispatch(setStatusTicket({
                                    status: partialLink.status
                                }))
                            }}>{partialLink.text}
                            </li>
                        ))}
                    </ul>
                }
            </Fragment>
        )) : linksArray.map(link => (
            <Fragment key={link.id}>
                <FancyNavLinks link={link} isActive={isActive} />
                {isActive == true && link.isTicket &&
                    <ul className={link.isTicket && link.classSecondNav}>
                        {link.isTicket.map(partialLink => (
                            <li key={partialLink.id} onClick={() => {
                                dispatch(setStatusTicket({
                                    status: partialLink.status
                                }))
                            }}>{partialLink.text}
                            </li>
                        ))}
                    </ul>
                }
            </Fragment>
        ))
    const smLinks = linksArray.map(link => (
        <Fragment key={link.id}>
            <li>
                <NavLink to={link.path}>
                    {link.text}
                </NavLink>
            </li>
            {isActive == true && link.isTicket &&
                <ul className={link.isTicket && link.classSecondNav}>
                    {link.isTicket.map(partialLink => (
                        <li key={partialLink.id} onClick={() => {
                            dispatch(setStatusTicket({
                                status: partialLink.status
                            }))
                        }}>{partialLink.text}
                        </li>
                    ))}
                </ul>
            }
        </Fragment>
    ))
    return (
        <>
            <div
                className={`col-md-3 ${isDepatrmentsPage == true && "full-height-nav"} `}>
                <div className={className}>
                    <h2>لوحة التحكم</h2>
                    <ul className="ul">
                        {links}
                    </ul>
                    {userType == "user" ? <UpgradePackage /> : <></>}
                </div>

            </div>
            <motion.div
                animate={{
                    height: menuStatus == true ? "88.2vh" : "0px",
                    paddingTop: menuStatus == true ? "30px" : "0px"
                }}
                className="sm-nav d-block d-md-none">
                <ul style={{
                    display: menuStatus == true ? "block" : "none"
                }}>
                    {smLinks}
                </ul>
            </motion.div>
        </>
    )
}

export default SideNav