import { fadeRight } from "../../../../Utils/Motions/motion"
import "./ExtraSmallCard.css"
import { motion } from "framer-motion"
const ExtraSmallCard = ({ title, partialTitle, description }) => {
    return (
        <motion.div
            variants={fadeRight}
            transition={{
                duration: .5,
                delay: 0
            }}
            initial="hidden"
            whileInView="visible"
            className="extra-small-card">
            <h4>{title}</h4>
            <p>
                {partialTitle}
            </p>
            <h5>
                {description}
            </h5>
        </motion.div>
    )
}

export default ExtraSmallCard