import { AuthAxios } from "./axios_handler"

class AllDepartmentsService {
    static endPoint = "authentications/sections"
    static getAll(page) {
        return AuthAxios.get(`${AllDepartmentsService.endPoint}/?page=${page}`)
    }
    static singleDepartments(id, page) {
        return AuthAxios.get(`${AllDepartmentsService.endPoint}/${id}/?page=${page}`)
    }
    static editSingleDepartments(body, id) {
        return AuthAxios.put(`${AllDepartmentsService.endPoint}/${id}/`, body)
    }
    static deleteDepartment(id) {
        return AuthAxios.delete(`${AllDepartmentsService.endPoint}/${id}/`)
    }

    static addDepartment(body) {
        return AuthAxios.post(`${AllDepartmentsService.endPoint}/`, body)
    }
}
export default AllDepartmentsService