const singleDepartmentColumns = [
    {
        title: 'الاسم',
        dataIndex: 'full_name',
        key: "full_name"
    },
    {
        title: 'الصلاحية',
        dataIndex: 'user_type',
        key: "user_type"
    },
];
export default singleDepartmentColumns
