import { animate } from "framer-motion"

export const fadeBottom = {
    visible: {
        opacity: 1,
        y: 0,
    },
    hidden: {
        opacity: 0,
        y: 100,
    }
}
export const fadeUp = {
    visible: {
        opacity: 1,
        y: 0,
    },
    hidden: {
        opacity: 0,
        y: -100,
    }
}
export const fadeLeft = {
    visible: {
        opacity: 1,
        x: 0,
    },
    hidden: {
        opacity: 0,
        x: -100,
    }
}
export const fadeRight = {
    visible: {
        opacity: 1,
        x: 0,
    },
    hidden: {
        opacity: 0,
        x: 100,
    }
}
export const rotate = {
    "initial": {
        rotate: 0
    },
    "tap": {
        rotate: 180
    }
}
export const exitMotion = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: { duration: 0.5 }
    },
    exit: {
        opacity: 0,
        fontSize: " 0px",
        transition: { duration: 0.5 }
    },
};
export const cardMotion = {
    initial: {
        y: 200,
        opacity: 0
    },
    animate: {
        y: 0,
        opacity: 1
    },
    exit: {
        y: 200,
        opacity: 0
    }
}
export const SignUpMotion = {
    initial: {
        opacity: 0
    },
    animate: {
        opacity: 1
    }
}