import { Tooltip } from "antd"
import { Controller } from "react-hook-form"
import { RandomCode } from "."
const DynamicInputs = ({ input, selectOptions, profileImageDetails, RandomCodeDetails, register, errors, control, setValue, placeholder }) => {
    if (input.isInput) {
        return <Tooltip placement="top" title={errors[input.name]?.message}>
            <input
                autoComplete="new-password"
                {...register}
                type={input.type}
                className={input.className}
                placeholder={input.placeholder != null ? input.placeholder : placeholder}
                aria-label={input.placeholder} />
        </Tooltip>
    }
    else if (input.isSelect) {
        return <Tooltip placement="top" title={errors[input.name]?.message}>
            <select
                {...register}
                className={input.className}
                defaultValue="الوظيفة">
                {input.defaultOption}
                {selectOptions}
            </select>
        </Tooltip>
    }
    else if (input.isProfileFile) {
        return <label className={input.labelClassName}>
            {profileImageDetails.icon}   {profileImageDetails.text}
            <Tooltip placement="top" title={errors[input.name]?.message}>
                <input
                    autoComplete="new-password"
                    {...register}
                    type="file" className={input.className} placeholder="تأكيد كلمة المرور" aria-label="تأكيد كلمة المرور"
                    onChange={profileImageDetails.handleChange} />
            </Tooltip>
        </label>

    }
    else if (input.isRandomCode == true) {
        return <Controller
            name={input.name}
            control={control}
            render={({ field }) => (
                <div class="input-group input-is-random">
                    <span className="input-group-text" id="basic-addon1"
                        onClick={() => {
                            const randomCode = RandomCode(8);
                            field.onChange(randomCode) // Adjust the number as needed
                            setValue(input.name, randomCode); // Update the form value
                        }}
                    >{RandomCodeDetails.icon}</span>
                    <Tooltip placement="top" title={errors[input.name]?.message}>
                        <input
                            {...register}
                            {...field}
                            autoComplete="new-password"
                            type={input.type}
                            className={input.className}
                            placeholder={input.placeholder}
                            aria-label={input.placeholder}
                            aria-describedby="basic-addon1"
                        />
                    </Tooltip>
                </div>
            )} />


    }
    else if (input.isTextArea) {
        return <textarea
            className={input.className}
            {...register}
        ></textarea>
    }
    else if (input.isPhoto) {
        return <label className={input.PhotolabelClassName}>
            {profileImageDetails.icon}
            <input
                type="file"
                onChange={profileImageDetails.handleChange} />
        </label>
    }
    else {
        return <></>
    }


}
export default DynamicInputs