import { createBrowserRouter } from "react-router-dom";
import Root from "../Root";
import { AddDepartment, AddMember, AdminControlPanel, Departments, EditMember, EditProfile, Members, Questions, SingleDepartment, SingleTicket, Supsucriptions, Tickets, JoinRequest, SelectPermissions, SingleAiChat, SearchHistory, MembersReports } from "../Pages";
import { Suspense, lazy } from "react";
import { PrivateAdminRoute, PrivateDa3emRoute, PrivateDepatmentsRoute, PrivatePermissionRoute, PrivateTickectRoute, PrivateUserRoutes } from "../Utils/PrivateRoutes";
const Home = lazy(() => import("../Pages/Home/Home"))
const ConfirmEmail = lazy(() => import("../Pages/ConfirmEmail/ConfirmEmail"))
const SuccessPayment = lazy(() => import("../Pages/SuccessPayment/SuccessPayment"))
const FaildPayment = lazy(() => import("../Pages/FaildPayment/FaildPayment"))
const SignUp = lazy(() => import("../Pages/SignUp/SignUp"))
const SignIn = lazy(() => import("../Pages/SignIn/SignIn"))
const ForgotPassword = lazy(() => import("../Pages/ForgotPassword/ForgotPassword"))
const ValidCode = lazy(() => import("../Pages/ValidCode/ValidCode"))
const StepOne = lazy(() => import("../Sections/SignUp/StepOne/StepOne"))
const CompanyStep = lazy(() => import("../Sections/SignUp/CompanyStep/CompanyStep"))
const ExistCompany = lazy(() => import("../Sections/SignUp/ExistCompany/ExistCompany"))
const NewCompany = lazy(() => import("../Sections/SignUp/NewCompany/NewCompany"))
const NormalUser = lazy(() => import("../Sections/SignUp/NormalUser/NormalUser"))
const ControlPanel = lazy(() => import("../Pages/UserDashboard/ControlPanel/ControlPanel"))
const AdminPanel = lazy(() => import("../Pages/AdminDashboard/AdminPanel/AdminPanel"))
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: <Suspense><Home /></Suspense>,
                index: true
            },
            {
                path: "/ConfirmEmail",
                element: <ConfirmEmail />
            },
            {
                path: "/SignUp",
                element: <Suspense><SignUp /></Suspense>,
                children: [
                    {
                        path: "/SignUp",
                        element: <Suspense><StepOne /></Suspense>
                    },
                    {
                        path: "CompanyStep",
                        element: <Suspense><CompanyStep /></Suspense>
                    },
                    {
                        path: "NormalUser",
                        element: <NormalUser />
                    },
                    {
                        path: "ExistCompany",
                        element: <ExistCompany />
                    },
                    {
                        path: "NewCompany",
                        element: <NewCompany />
                    }
                ]
            },
            {
                path: "/sign-in",
                element: <Suspense><SignIn /> </Suspense>
            },
            {
                path: "/ForgotPassword",
                element: <Suspense> <ForgotPassword /></Suspense>
            },
            {
                path: "/ValidCode",
                element: <Suspense><ValidCode /></Suspense>
            },
            {
                path: "/Supsucriptions",
                element: <Supsucriptions />
            },
            {
                path: "/SuccessPayment",
                element: <SuccessPayment />
            },
            {
                path: "/FaildPayment",
                element: <FaildPayment />
            },
            {
                element: <PrivateUserRoutes />,
                children: [
                    {
                        path: "/ControlPanel",
                        element: <Suspense>
                            <ControlPanel />
                        </Suspense>,
                        children: [
                            {
                                path: "/ControlPanel",
                                element: <Questions />,
                            },
                            {
                                path: "EditProfile",
                                element: <EditProfile />
                            },
                            {
                                path: "Tickets",
                                element: <Tickets />
                            },
                            {
                                path: "Tickets/SingleTicket/:id",
                                element: <SingleTicket />
                            },
                            {
                                path: "chat/:p",
                                element: <SingleAiChat />
                            },
                            {
                                path: "SearchHistory",
                                element: <SearchHistory pageName="user" />
                            }
                        ]
                    }
                ]
            },
            {
                element: <PrivateAdminRoute />,
                children: [
                    {
                        path: "/AdminPanel",
                        element: <Suspense><AdminPanel /></Suspense>,
                        children: [
                            {
                                path: "/AdminPanel",
                                element: <AdminControlPanel />
                            },
                            {
                                path: "SingleDepartment/:id",
                                element: <SingleDepartment />
                            },
                            {
                                path: "MembersReports",
                                element: <MembersReports />
                            },
                            {
                                path: "AddMember",
                                element: <AddMember />
                            },
                            {
                                path: "Members",
                                element: <Members />
                            },
                            {
                                element: <PrivateDepatmentsRoute />,
                                children: [
                                    {
                                        path: "Departments",
                                        element: <Departments />
                                    },
                                    {
                                        path: "AddDepartment",
                                        element: <AddDepartment />
                                    },
                                ]
                            },
                            {
                                path: "EditMember/:id",
                                element: <EditMember />
                            },
                            {
                                path: "JoinRequest",
                                element: <JoinRequest />
                            },
                            {
                                element: <PrivatePermissionRoute />,
                                children: [
                                    {
                                        path: "SelectPermissions",
                                        element: <SelectPermissions />
                                    },
                                ]
                            },
                            {
                                path: "EditProfile",
                                element: <EditProfile />
                            },
                            {
                                element: <PrivateDa3emRoute />,
                                children: [
                                    {
                                        path: "Questions",
                                        element: <Questions pageName="admin" />
                                    }
                                ]
                            },
                            {
                                element: <PrivateTickectRoute />,
                                children: [
                                    {
                                        path: "Tickets",
                                        element: <Tickets />
                                    },
                                    {
                                        path: "tickets/SingleTicket/:id",
                                        element: <SingleTicket />
                                    },
                                ]
                            },
                            {
                                path: "Questions/chat/:p",
                                element: <SingleAiChat />
                            },
                            {
                                path: "SearchHistory",
                                element: <SearchHistory />
                            }
                        ]
                    }
                ]
            },
        ]

    }
])
export default router