import { Widget } from "../../../"
import "./WidgetChecker.css"
const WidgetChecker = ({ widget, widgetData }) => {
    if (widget.id == 1) {
        return <Widget
            key={widget.id}
            icon={widget.icon}
            textOne={widget.textOne}
            textTwo={<>{widgetData?.registered_emp}/{widgetData?.total_emp} <span>({widgetData?.available_emp} أعضاء متبقي)</span></>}
            isBtn={widget.isBtn}
        />
    }
    else if (widget.id == 2) {
        return <Widget
            key={widget.id}
            icon={widget.icon}
            textOne={widget.textOne}
            textTwo={`${widgetData?.solved_problems} مشكلة (${widgetData?.current_month_name})`}
            isBtn={widget.isBtn}
        />
    }
    else {
        return <Widget
            key={widget.id}
            icon={widget.icon}
            textOne={widget.textOne}
            textTwo={widget.textTwo}
            isBtn={widget.isBtn}
        />
    }
}

export default WidgetChecker