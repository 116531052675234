import Settings from "../../../Images/Home/settings.svg"
import Lampe from "../../../Images/Home/lampe.svg"
import StarsIcon from "../../../Images/Home/stars-2.svg"
const AboutSwiperData = [
    {
        id: 1,
        icons: [
            {
                id: 1,
                icon: Settings,
                alt: "Settings"
            },
            {
                id: 2,
                icon: Lampe,
                alt: "lampe"
            },
            {
                id: 3,
                icon: StarsIcon,
                alt: "StarsIcon"
            }
        ],
        text: " نص بسيط من سطرين يوضح الميزة الاولى"
    },
    {
        id: 2,
        icons: [
            {
                id: 1,
                icon: Settings,
                alt: "Settings"
            },
            {
                id: 2,
                icon: StarsIcon,
                alt: "StarsIcon"
            },
            {
                id: 3,
                icon: Lampe,
                alt: "lampe"
            }
        ],
        text: " نص بسيط من سطرين يوضح الميزة الاولى"
    },
    {
        id: 3,
        icons: [
            {
                id: 1,
                icon: StarsIcon,
                alt: "StarsIcon"
            },
            {
                id: 2,
                icon: Settings,
                alt: "Settings"
            },
            {
                id: 3,
                icon: Lampe,
                alt: "lampe"
            }
        ],
        text: " نص بسيط من سطرين يوضح الميزة الاولى"
    }
]
export default AboutSwiperData