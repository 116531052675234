import { createSlice } from "@reduxjs/toolkit";

const StyleSlice = createSlice({
    name: "StyleSlice",
    initialState: {
        menu: false
    },
    reducers: {
        munuChangeStatus: (state) => {
            state.menu = !state.menu
        }
    }
})
const { actions, reducer } = StyleSlice;
export const { munuChangeStatus } = actions;
export default reducer