import "./SuccessPayment.css"
import { useLocation } from "react-router-dom"
import useTestGet from "../../Custom Hooks/useTestGet"
import PaymentService from "../../Services/PayMentService"
import Lottie from "lottie-react"
import Success from "../../Assets/Images/Payment/success.json"
import { usePath } from "../../Custom Hooks"
const SuccessPayment = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('order_id');
    const handleNavigate = usePath()
    // const [data, loading] = useTestGet(PaymentService.get(orderId))
    return (
        <>
            <div className="container-fluid success-payment">
                <div className="row">
                    <div className="col-md-6 f">
                        <Lottie
                            animationData={Success}
                            loop={false} speed={0.5}
                            className="lottie-success"
                        />
                    </div>
                    <div className="col-md-6">
                        <div className="text">
                            <h2>عملية الدفع</h2>
                            <h4>تمت عملية الدفع بنجاح شكرا لك </h4>
                            <div className="flex-btns">
                                <a className="confirm" onClick={() => handleNavigate("/")}>اذهب للصفحة الرئيسية</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SuccessPayment