import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import "./Form.css"
import { BtnWithCon } from "../../../Components"
import { JobData } from "../../../Assets/Data"
import { Base64, DynamicInputs, getLoadingText } from "../../../Helpers"
import { Fragment, useState } from "react"
import SignUpService from "../../../Services/SignUpService";
import { useTestPost } from "../../../Custom Hooks";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PiUserCirclePlus } from "react-icons/pi";

const Form = ({ className, buttonClassName, buttonContainerClass, schema, formArray }) => {
    const { register, handleSubmit, setValue, control, formState: {
        errors, isSubmitting,
    } } = useForm({ mode: "onBlur", resolver: zodResolver(schema) })
    const [user_type, setUserType] = useState(window.sessionStorage.getItem("user_type"))
    const [image, setImage] = useState()
    const [imageName, setImageName] = useState()
    const [loading, postFunc, , error] = useTestPost(SignUpService.post, (res) => {
        window.location.assign("/ConfirmEmail")
    })
    const handleImage = async (e) => {
        const file = e.target.files[0];
        const base64 = await Base64(file)
        setImageName(file.name)
        setImage(base64)
    };


    const text = getLoadingText(loading, {
        null: "تأكيد",
        success: "تم التسجيل بنجاح",
        faild: error,
        loading: "الرجاء الانتظار"
    })
    const onSubmit = (data) => {
        if (image != null) {
            postFunc({ ...data, user_type, image })
        }
        else {
            postFunc({ ...data, user_type })
        }

    }


    const jobOptions = JobData.map(group => (
        <optgroup key={group.id} label={group.label}>
            {group.values.map(option => <option key={option.id} value={option.value}>{option.text}</option>)}
        </optgroup>
    ))


    const personalInputs = formArray.filter(row => row.information == "personal").map(input => (
        <div key={input.id} className="row  g-3">
            {input.row.map((item, i) =>
                <Fragment key={item.id}>
                    {
                        <div className="col">
                            <div className="input-group">
                                <DynamicInputs
                                    errors={errors}
                                    register={register(item.name)}
                                    input={item}
                                    selectOptions={jobOptions}
                                />
                                <span className="input-group-text" id="basic-addon1"> {item.groupIcon}</span>
                            </div>
                        </div>}
                </Fragment>
            )}

        </div>
    ))

    const photoInput = formArray.filter(row => row.information == "photo").map(item => (
        <Fragment key={item.id}>
            {
                item.row.map((item, i) => <Fragment key={item.id}>
                    <div key={item.id} className="col">
                        <DynamicInputs
                            control={control}
                            errors={errors}
                            register={register(item.name)}
                            input={item}
                            profileImageDetails={{
                                handleChange: handleImage,
                                icon: <PiUserCirclePlus size={200} />,
                                text: imageName != null ? imageName : "رفع صورة الشركة"
                            }}
                        />
                    </div>
                </Fragment>)
            }
        </Fragment>
    ))
    const jobInputs = formArray.filter(row => row.information == "job").map(input => (
        <div key={input.id} className="row  g-3">
            {input.row.map((item, i) => <Fragment key={item.id}>
                {
                    <div key={input.id} className="col">
                        <div className={`input-group ${item.isExist == true && "w-45"}`}>
                            <DynamicInputs
                                setValue={setValue}
                                control={control}
                                errors={errors}
                                register={register(item.name)}
                                input={item}
                                selectOptions={jobOptions}
                                RandomCodeDetails={{
                                    icon: <GiPerspectiveDiceSixFacesRandom className="icon-dice" />
                                }}
                            />
                            <span className="input-group-text" id="basic-addon1"> {item.groupIcon}</span>
                        </div>
                    </div>
                }

            </Fragment>
            )}

        </div>
    ))
    return (
        <form className={className} onSubmit={handleSubmit(onSubmit)}>
            <div className="upload-photo-con">
                {photoInput}
            </div>
            <div className="flex-start">
                <div className="personal-details">
                    <h5>المعلومات الشخصية</h5>
                </div>
            </div>
            {personalInputs}
            <div className="flex-start mt-2 mt-lg-5">
                <div className="personal-details">
                    <h5>المعلومات الوظيفية</h5>
                </div>
            </div>
            {jobInputs}

            <div className="privacy-policy">
                <p>بالتسجيل فانا اوافق على <span>سياسات منصة داعم</span></p>
            </div>
            <BtnWithCon
                buttonContainerClass={buttonContainerClass}
                buttonClassName={buttonClassName}
                text={text} />

        </form>
    )
}



export default Form
