import { configureStore } from "@reduxjs/toolkit";
import userDetailsSlice from "./userDetailsSlice"
import PermissionsSlice from "./PermissionsSlice"
import StylesSlice from "./StylesSlice"
const store = configureStore({
    reducer: {
        userDetailsSlice: userDetailsSlice,
        PermissionsSlice: PermissionsSlice,
        StylesSlice: StylesSlice
    },


})
export default store