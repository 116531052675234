import "./SelectPermissions.css"
import { useEffect, useState } from "react"
import { useTestGet, useTestPost } from "../../../Custom Hooks"
import PermissionService from "../../../Services/PermissionService"
import { AdminPermissionsData, UserPermissionsData } from "../../../Assets/Data"
import { getLoadingText } from "../../../Helpers"
import { BlueGlassCon, BorderdP } from "../../../Components"

const SelectPermissions = () => {
    const [adminFormData, setAdminFormData] = useState({
        "control_permissions": null,
        "create_section": null,
        "use_da3em": null,
        "use_ticket": null
    })
    const [userFormData, setUserFormData] = useState({
        "use_da3em": null,
        "use_ticket": null
    })
    const [data,] = useTestGet(PermissionService.getAll())
    const [loading1, postFunc] = useTestPost(PermissionService.editPermissions, () => {
        window.location.reload()
    })
    useEffect(() => {
        if (data?.permissions) {
            setAdminFormData({
                "control_permissions": data.permissions[0].control_permissions,
                "create_section": data.permissions[0].create_section,
                "use_da3em": data.permissions[0].use_da3em,
                "use_ticket": data.permissions[0].use_ticket
            })
            setUserFormData({
                "use_da3em": data.permissions[1].use_ticket,
                "use_ticket": data.permissions[1].use_da3em
            })
        }

    }, [data]);

    const handleClick = () => {
        postFunc(
            {
                "admin": {
                    ...adminFormData
                },
                "user": {
                    ...userFormData
                }
            }
        )
    }

    const adminPermissions = AdminPermissionsData.map(item => (
        <div className="switch-text" key={item.id}>
            <p>{item.label}</p>
            <div className="switch-input"
                style={{
                    backgroundColor: adminFormData[item.name] == true ? "rgba(15, 89, 213, 1)" : " transparent"

                }}>
                <div className="circle"
                    onClick={() => handleChangeAdmin(item.name)} style={{
                        right: adminFormData[item.name] == true ? "25px" : "1px"
                    }}></div>
            </div>
        </div>
    ))
    const userPermissionsData = UserPermissionsData.map(item => (
        <div className="switch-text" key={item.id}>
            <p>{item.label}</p>
            <div className="switch-input"
                style={{
                    backgroundColor: userFormData[item.name] == true ? "rgba(15, 89, 213, 1)" : " transparent"

                }}>
                <div className="circle"
                    onClick={() => handleChangeUser(item.name)} style={{
                        right: userFormData[item.name] == true ? "25px" : "1px"
                    }}></div>
            </div>
        </div>
    ))
    const handleChangeAdmin = (name) => {
        setAdminFormData(prev => {
            return {
                ...prev,
                [name]: !prev[name]
            }
        }
        )
    }
    const handleChangeUser = (name) => {
        setUserFormData(prev => {
            return {
                ...prev,
                [name]: !prev[name]
            }
        }
        )
    }
    const text = getLoadingText(loading1, {
        null: "حفظ",
        success: "تم اضافة الصلاحيات بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    return (
        <>
            <BlueGlassCon additionalClassName="control-permissions-con">
                <BorderdP additionalClassName="mb-45">تحديد الصلاحيات</BorderdP>
                <div className="control-permission">
                    <h5>الادمن</h5>
                    <div className="hr"></div>
                    <div className="switch-permissions-con">
                        {adminPermissions}
                    </div>
                    <h5>المستخدم</h5>
                    <div className="hr"></div>
                    <div className="switch-permissions-con">
                        {userPermissionsData}
                    </div>
                    <div className="save-permission">
                        <a className="save" onClick={handleClick}>
                            {text}
                        </a>
                    </div>
                </div>
            </BlueGlassCon>
        </>
    )
}

export default SelectPermissions