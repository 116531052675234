
import { z } from "zod";
const schema = z.object({
    full_name: z.string(),
    password: z.string(),
    old_password: z.string(),
    password2: z.string(),
    career: z.string(),
    phone_number: z.string()
}).refine((data) => data.password == data.password2, {
    message: "Passwords don't match",
    path: ["password2"], // path of error
});
export default schema