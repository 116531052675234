import "./Departments.css"
import { BlueGlassCon, BorderdP, EditRemoveTableCon, TableCon } from "../../../Components";
import { usePath, useTestGet, useTestPost } from "../../../Custom Hooks";
import AllDepartmentsService from "../../../Services/AllDepartmentsService";
import { DepartmentsCols } from "../../../Assets/Data";
import { useState } from "react";

const Departments = () => {
    const [page, setPage] = useState(1)
    const navigate = usePath()
    const [dataSource, loading] = useTestGet(AllDepartmentsService.getAll(page), { page })
    const [, deleteFunc] = useTestPost(AllDepartmentsService.deleteDepartment, () => {
        window.location.reload()
    })
    const handleNavigate = () => {
        navigate("/AdminPanel/addDepartment")
    }

    const cols = DepartmentsCols.map(item => {
        if (item.title == "تعديل") {
            item.render = (record) => (
                <>
                    <EditRemoveTableCon
                        handleNavigate={() => navigate(`/AdminPanel/SingleDepartment/${record}`)}
                        handleDelete={() => deleteFunc(record)}
                    />
                </>
            )
            return item
        }
        else {
            return item
        }
    })
    return (
        <>
            <BlueGlassCon additionalClassName="members-con">
                <div className="text-add-members-con">
                    <BorderdP>الأقسام</BorderdP>
                    <a className="add-member-btn" onClick={handleNavigate}>اضافة قسم</a>
                </div>
                <TableCon
                    columns={cols}
                    dataSource={dataSource?.sections}
                    loading={loading}
                    onChange={(page) => setPage(page)}
                    conClassName="table-add-member-con"
                    pageSize={5}
                    totalPages={dataSource?.pages}
                />
            </BlueGlassCon>
        </>
    )
}

export default Departments