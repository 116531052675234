import "./JoinUs.css"
import RightLeftIcon from "../../../Assets/Images/Home/rightLeft.svg"
import { cardMotion, exitMotion, rotate } from "../../../Utils/Motions/motion";
import { JoinUsCard, JoinUsGlassCon, RotateSwitcher, SwitchBtn } from "../../../Components";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import { useState } from "react";
const JoinUs = () => {
    const [status, setStatus] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const handleSwitcherClick = () => {
        setIsClicked(!isClicked)
    }
    return (
        <div className="container-fluid join-us">
            <JoinUsGlassCon>
                <div className="text d-block d-xl-none">
                    <h3>اشترك في داعم</h3>
                    <p>اختر باقة الاشتراك المناسبة لك</p>
                </div>
                <div className="first-row">
                    <SwitchBtn
                        mainSwitchClass="join-us-switch-btn"
                        isClicked={status}
                        handleClick={() => setStatus(!status)}
                        switchContainerClass="switch-btn-con"
                        circleClass="circle"
                        switchChoices={{
                            first: "شهريا",
                            second: "سنويا"
                        }}
                    />
                    <div className="text d-none d-xl-block">
                        <h3>اشترك في داعم</h3>
                        <p>اختر باقة الاشتراك المناسبة لك</p>
                    </div>
                    <RotateSwitcher
                        onClick={handleSwitcherClick}
                        isClicked={isClicked} />
                </div>
                <div className="second-row">
                    <AnimatePresence>
                        {isClicked == true ? <>
                            <JoinUsCard key="companiesCard1"
                                delay={.5}
                                title="اسم الباقة"
                                price="65" />
                            <JoinUsCard key="companiesCard2"
                                delay={0}
                                title="اسم الباقة"
                                price="65" />
                            <JoinUsCard key="companiesCard3"
                                delay={.5}
                                title="اسم الباقة"
                                price="65" />
                        </> : <>
                            <JoinUsCard key="singleCard1"
                                delay={.5}
                                title="اسم الباقة"
                                price="65" />
                            <JoinUsCard key="singleCard2"
                                delay={0}
                                title="اسم الباقة"
                                price="65" />
                            <JoinUsCard key="singleCard3"
                                delay={.5}
                                title="اسم الباقة"
                                price="65" />
                        </>}
                    </AnimatePresence>
                </div>
            </JoinUsGlassCon>
        </div>
    )
}

export default JoinUs