import { Fragment } from "react"
import "./Steps.css"

const Steps = ({ count }) => {
    const steps = [...Array(count)].map((_, i) => (
        <Fragment key={i}>
            <div className={"step-one" + " " + `step-${i + 1}`}>{i + 1}</div>
            <div className="line"></div>
        </Fragment>
    ))
    return (
        <>
            {steps}
        </>
    )
}

export default Steps