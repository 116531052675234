const Tickets = [

    {
        title: 'الرقم',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: "",
        dataIndex: "user_has_new_comment",
        key: "user_has_new_comment",
        render: (record) => (
            <>
                {record == true ? <div className="notification-table"></div> : <></>}

            </>

        )
    },
    {
        title: 'العنوان',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'التاريخ',
        dataIndex: 'created_at',
        key: 'created_at',
    },
    {
        title: 'الحالة',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: <svg className="burger-svg" width="39" height="21" viewBox="0 0 39 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="39" height="3" rx="1.5" fill="white" />
            <rect y="9" width="28" height="3" rx="1.5" fill="white" />
            <rect y="18" width="16" height="3" rx="1.5" fill="white" />
        </svg>,
        dataIndex: 'id',
        key: 'id',
        isBtn: true
    }
]
export default Tickets