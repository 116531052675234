import "./BlueWhiteGradientCon.css"

const BlueWhiteGradientCon = ({ children, additionalClassName }) => {
    return (
        <div className={`blue-white-gradient-con ${additionalClassName}`}>
            {children}
        </div>
    )
}

export default BlueWhiteGradientCon