import { JoinDate } from "../../../../Components"

const MembersData = [

    {
        title: 'الرقم',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'الاسم',
        dataIndex: 'full_name',
        key: 'full_name',
    },
    {
        title: 'القسم',
        dataIndex: 'section',
        key: 'section',
    },
    {
        title: 'الصلاحية',
        dataIndex: 'user_type',
        key: 'user_type',
    },
    {
        title: 'تاريخ الانضمام',
        dataIndex: 'created_at',
        key: "created_at",
        align: "center",
        render: (record) => (
            <JoinDate>
                {record}
            </JoinDate>
        )
    },
    {
        title: 'تعديل',
        dataIndex: 'id',
        key: "id"
    }

]
export default MembersData