const selects = [
    {
        id: 1,
        isSelect: true,
        name: "section_name",
        label: "حسب القسم",
        className: "form-control form-select",
        defaultOption: <option value="الوظيفة" selected disabled hidden>القسم</option>,
    },
    {
        id: 2,
        isSelect: true,
        name: "month",
        label: "حسب الشهر",
        className: "form-control form-select",
        defaultOption: <option value="الوظيفة" selected disabled hidden>الشهر</option>,
        options: [
            {
                id: 1,
                label: 1,
                value: 1
            },
            {
                id: 2,
                label: 2,
                value: 2
            },
            {
                id: 3,
                label: 3,
                value: 3
            },
            {
                id: 4,
                label: 4,
                value: 4
            },
            {
                id: 5,
                label: 5,
                value: 5
            },
            {
                id: 6,
                label: 6,
                value: 6
            },
            {
                id: 7,
                label: 7,
                value: 7
            },
            {
                id: 8,
                label: 8,
                value: 8
            },
            {
                id: 9,
                label: 9,
                value: 9
            },
            {
                id: 10,
                label: 10,
                value: 10
            },
            {
                id: 11,
                label: 11,
                value: 11
            },
            {
                id: 12,
                label: 12,
                value: 12
            }
        ]
    },
    {
        id: 3,
        isSelect: true,
        label: "حسب النتيجة",
        name: "like",
        className: "form-control form-select",
        defaultOption: <option value="الوظيفة" selected disabled hidden>النتيجة</option>,
        options: [
            {
                id: 1,
                label: "Like",
                value: "True"
            },
            {
                id: 2,
                label: "disLike",
                value: "False"
            }
        ]
    }
]

export default selects