import { AuthAxios } from "./axios_handler"

class TicketsService {
    static endPoint = "ticket"
    static createTicket(body) {
        return AuthAxios.post(`${TicketsService.endPoint}/ticket/`, body)
    }
    static getAllTickets(page, status) {
        return AuthAxios.get(`${TicketsService.endPoint}/ticket-list/?page=${page}&status=${status}`)
    }
    static comment(body, id) {
        return AuthAxios.post(`${TicketsService.endPoint}/ticket_comment/${id}/`, body)
    }
    static getTicketsDetails(id) {
        return AuthAxios.get(`${TicketsService.endPoint}/ticket/${id}/`)
    }
}
export default TicketsService