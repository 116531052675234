import "./EditProfile.css"
import ProfileImg from "../../../Assets/Images/Dashboard/360_F_87479758_ipnPzqEE3JuhCF6yAlYrKk0Yui8l34Xc 1.png"
import useTestPost from "../../../Custom Hooks/useTestPost"
import ProfileService from "../../../Services/ProfileService"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { editProfileArr, subDetails } from "../../../Assets/Data"
import { DynamicInputs, getLoadingText } from "../../../Helpers"
import schema from "../../../Utils/ValidtionsSchemas/Profile/ProfileSchema"
import { useTestGet } from "../../../Custom Hooks"
import { useEffect } from "react"
import { BlueWhiteGradientCon, BorderdP, Loader } from "../../../Components"
const EditProfile = () => {
    const [loading, putFunc] = useTestPost(ProfileService.put, () => {
        window.location.reload()
    })
    const [data1] = useTestGet(ProfileService.get())
    const { register, handleSubmit, setValue, formState: {
        errors
    } } = useForm({
        mode: "onBlur",
        resolver: zodResolver(schema)
    })
    const inputs = editProfileArr.map(row => (
        <div className="row">
            {row.row.map(input => (
                <div className="col">
                    <DynamicInputs
                        errors={errors}
                        register={register(input.name)}
                        input={input}
                    />
                </div>
            ))}
        </div>
    ))
    const onSubmit = (data) => {
        const submissionData = Object.fromEntries(
            Object.entries(data).filter(([_, value]) => value !== "")
        );
        putFunc(submissionData);
    }
    const text = getLoadingText(loading, {
        null: "حفظ التغييرات",
        success: "تم تعديل المعلومات بنجاح",
        faild: "حدث خطأ ما",
        loading: "الرجاء الانتظار"
    })
    const subs = subDetails.map((subDetaile, i) => (
        <>
            <div key={subDetaile.id} className="sub-details-edit">
                <h6 className="mb-0">
                    {subDetaile.label}
                </h6>
                <p className="mb-0">{data1 && data1[subDetaile.name]}</p>
            </div>
            {i != subDetails.length - 1 && <div className="hr"></div>}
        </>
    ))
    return (
        <>
            {data1 != null ?
                <>
                    <div className="edit-profile-main-con">
                        <div className="two-widget-profile">
                            <BlueWhiteGradientCon additionalClassName="profile-details">
                                <img src={ProfileImg} />
                                <h4>{data1 && data1.full_name}</h4>
                                <h5>{data1 && data1.username}@</h5>
                                <h6>{data1 && data1.email}</h6>
                            </BlueWhiteGradientCon>
                            <BlueWhiteGradientCon additionalClassName="profile-details-second">
                                <BorderdP additionalClassName="profile-details-p">تفاصيل المستخدم</BorderdP>
                                {subs}
                            </BlueWhiteGradientCon>
                        </div>
                        <BlueWhiteGradientCon additionalClassName="one-widget-profile">
                            <BorderdP additionalClassName="profile-edit-p">تفاصيل الحساب</BorderdP>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {inputs}
                                <div className="btn-con-widget">
                                    <button>{text}</button>
                                </div>
                            </form>
                        </BlueWhiteGradientCon>
                    </div>
                </>
                : <Loader />}

        </>
    )
}

export default EditProfile