import Logo from "../../Images/Dashboard/Group 16.svg"
import Vector from "../../Images/Dashboard/Group.svg"
import Vector2 from "../../Images/Dashboard/Vector.svg"
import Vector3 from "../../Images/Dashboard/Vector3.svg"
const UserLinks = [
    {
        id: 1,
        liClassName: "li",
        text: "لوحة داعم",
        path: "/ControlPanel",
        icon: Logo
    },
    {
        id: 2,
        liClassName: "li",
        text: "الملف الشخصي",
        path: "/ControlPanel/EditProfile",
        icon: Vector
    },
    {
        id: 3,
        liClassName: "li",
        text: "التيكيت",
        classSecondNav: "second-ul admin-second-ul",
        isTicket: [
            {
                id: 1,
                text: "تيكت مفتوحة",
                status: "open"
            },
            {
                id: 2,
                text: "تيكت قيد المتابعة",
                status: "in_progress"
            },
            {
                id: 3,
                text: "تيكت مغلقة",
                status: "closed"
            }
        ],
        path: "/ControlPanel/Tickets",
        icon: Vector2
    },
    {
        id: 4,
        liClassName: "li",
        text: "سجل البحث",
        path: "SearchHistory",
        icon: Vector3
    },


]
export default UserLinks