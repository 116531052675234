
import "./ExistCompany.css"
import { Form } from "../../";
import { Steps } from "../../../Components";
import schema from "../../../Utils/ValidtionsSchemas/SignUp/existCompanySchema";
import { motion } from "framer-motion"
import { SignUpMotion } from "../../../Utils/Motions/motion";
import { existCompany } from "../../../Assets/Data";
const ExistCompany = () => {
    return (
        <motion.div
            variants={SignUpMotion}
            initial="initial"
            animate="animate"
            exit="initial"
            className="zIndex"
            transition={{
                duration: .7
            }}>
            <div className="steps-one sign-up-form-one">
                <Steps count={2} />
            </div>
            <Form
                formArray={existCompany}
                schema={schema}
                length={5}
                className="sign-up-form exist "
                buttonClassName="next next-company"
                buttonContainerClass="next-con next-normal-Company"
            />

        </motion.div>
    )
}

export default ExistCompany