import "./UpgradePackage.css"

const UpgradePackage = () => {
    return (
        <div className="upgrade-package">
            <h5>تطوير باقة الاشتراك</h5>
            <p>قم بالاشتراك بباقة سنوية  <br />وتمتع بمدة اطول مع دائم!</p>
            <a>تطوير</a>
        </div>
    )
}

export default UpgradePackage